import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const BotonRound = ({icono, titulo, evento}) => {
    return (
        <>
         <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                <Tooltip id={`tooltip-top`}>
                    {titulo}
                </Tooltip>
                
                }
            >
         <button className='btnround ml2' onClick={()=>evento()}><i class={icono}></i></button>
         </OverlayTrigger>
        </>
      )
}

export default BotonRound