import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GetData } from '../../services/GetData';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AG_GRID_LOCALE_ES } from '../../components/locale_es'
import { Link } from 'react-router-dom';
import * as XLSX from "xlsx";
import config from '../../config';
import { getSede, getToken } from '../../services/Usuario';

const AlumnosMas2AsigPerdidasPer = () => {
    const [ListadoAlumnos, setListadoAlumnos] = useState([])  
    const [ListadoDatos, setListadoDatos] = useState([])  
    const [Periodo, setPeriodo] = useState(0)  
    const [EstuActivos, setEstuActivos] = useState(0)  
    const [PorcEstuPerdidos, setPorcEstuPerdidos] = useState(0)  
    const [PeriodoActual, setPeriodoActual] = useState(0)  
    const [TotalFilteredRowCount, setTotalFilteredRowCount] = useState(0)  
    const [Loading, setLoading] = useState(false)  
    const gridRef = useRef();

    const [columnDefs] = useState([
        { field: 'id_matricula', headerName: 'ID', sortable: true, filter: true, width:100},
        { field: 'nombre_grado', headerName: 'Grado', sortable: true, filter: true, width:100},
        { field: 'id_grupo', headerName: 'Grupo', sortable: true, filter: true, width:100},
        { field: 'nombres_apellidos_al', headerName: 'Nombre Alumno', sortable: true, filter: true, width:320, 
         cellRenderer: (params) => {
              return <Link className='font-weight-bold' to={`/home/detalleguia/${params.data.id_matricula}`}>{params.data.nombres_apellidos_al} </Link>
        }  },
        { field: 'asignaturas_perdidas', headerName: 'N° Perd', sortable: true, filter: true, width:100 },
        { field: 'asignaturas_con_nota_baja', headerName: 'Asignaturas Perdidas', sortable: true, filter: true, width:460 },
       
    ])

    const defaultColDef = useMemo(() => {
        return {
          editable: false,
          sortable: true,
          filter: true,
          resizable: true,
        };
      }, []);
  
      const clearFilter = useCallback(() => {
        console.log(gridRef.current.api.allColumnFilters)
        gridRef.current.api.setFilterModel(null);
      }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
            
            const periodoResult = await GetData('periodos/actual/', null);
            const estuactivos = localStorage.getItem('numestudiantes_cand')
            setEstuActivos(estuactivos)
            setPeriodo(periodoResult.periodo);
            setPeriodoActual(periodoResult.periodo);
            

           
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setLoading(false);
        }
    };

    fetchData();
}, []);

    useEffect(() => {
      const fetchData = async () => {
          try {
              setLoading(true);
              const listado = await GetData('estadisticas/alumnosmas2perdidasperiodo/' + Periodo, null);  
              setListadoAlumnos(listado.alumnos);

              const lista = listado.alumnos;
                let datos = [];
                lista.forEach((alumno, index) => {

                  datos = [...datos, {
                    id_matricula: alumno.id_matricula,
                    nombre_grado: alumno.nombre_grado,
                    id_grupo: alumno.id_grupo,   
                    nombres_apellidos_al: alumno.nombres_apellidos_al,
                    asignaturas_perdidas: alumno.asignaturas_perdidas,
                    asignaturas_con_nota_baja: alumno.asignaturas_con_nota_baja
                }]
            
                });        
                setTotalFilteredRowCount(lista.length)
                setListadoAlumnos(datos)
                setLoading(false);

          } catch (error) {
            console.error('Error al obtener los datos:', error);
            setLoading(false);
          }
        }
        fetchData();
    }, [Periodo])
    
    useEffect(() => {
      if (gridRef.current) {
        const gridApi = gridRef.current.api;
        if (gridApi) {
          const updateFilteredRowCount = () => {
            console.log(gridApi.getModel())
            setTotalFilteredRowCount(gridApi.getModel().rowsToDisplay.length);
          };
          gridApi.addEventListener('filterChanged', updateFilteredRowCount);
          return () => {
            gridApi.removeEventListener('filterChanged', updateFilteredRowCount);
          };
        }
      }
    }, [gridRef]);
    
    
    useEffect(() => {
        const porce = (TotalFilteredRowCount / EstuActivos) * 100
        setPorcEstuPerdidos(porce.toFixed(1))
    }, [TotalFilteredRowCount])
    


    const onSelectionChanged = (e) => {

    }

  const proc_eliminarGuias = () => {
      
  
  }

  const btmExportar = () => {
    let rowData = [];
    gridRef.current.api.forEachNodeAfterFilter(node => {
        // Copia los datos del nodo pero excluye el campo cod_grado
        const { id_grado, id_periodo, ...dataWithoutCodGrado } = node.data;
        rowData.push(dataWithoutCodGrado);
    });

      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(rowData);

      XLSX.utils.book_append_sheet(wb, ws, "Listado");
     // const max_width = rowData.reduce((w, r) => Math.max(w, r.name.length), 10);
     // ws["!cols"] = [ { wch: max_width } ];
      XLSX.utils.sheet_add_aoa(ws, [["Num. Mat", "Grado", "Grupo", "Alumno", "No. Perd", "Asignaturas Perdidas" ]], { origin: "A1" });

      const wscols = [
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 40 },
        { wch: 20 },
        { wch: 45 }
    ];

      ws["!cols"] = wscols;
      
      XLSX.writeFile(wb, "ListadoAlumnosAsigPerdidas.xlsx")
  }



  
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_ES;
  }, []);

  const Imprimir = () => {
        const url_informe = config.API_URL_INFORMES;
        const url = url_informe + '/listado_alumnos_perdidos_p.php?id_periodo='+Periodo+'&token='+getToken()+'&db='+getSede()
        window.open(url, "_blank");    
  }
  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Alumnos con más de 2 Asignaturas Perididas, Período: {Periodo}</div>                    

                <div className="card-body pb-0 mt-3">
                <div className='row'>
                        <div className='col-md-2'>
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>      
                </div>
                <div className='alert alert-primary alert-dismissible fade show'>
                    En está página puede visualización los alumnos con más de 2 asignaturas perdidas. 
                    Esta herramienta te permite obtener información detallada sobre los alumnos que tienen más de 2 asignaturas perdidas en nuestra institución educativa.
                    Puede utilizar los <strong>filtros</strong> para personalizar tu búsqueda y consulta. Puedes filtrar por grado, grupo o cualquier otro criterio relevante para obtener información específica según tus necesidades.<br />
                    Además de visualizar los datos en la página, también puedes exportarlos a formatos de archivo como <strong>Excel</strong> para un análisis más detallado o para compartir con otros interesados.
                </div>    
                <div className='row'>
                    <div className='col-md-6 mb-2'>
                        <button type="button" className="btn btn-success ml-2 " onClick={() => btmExportar()}  >Exportar a Excel</button>  
                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={Imprimir} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Imprimir Listado">
                            <i className="bi bi-clipboard-plus"></i> Imprimir
                        </button>                      
                    </div>
                    <div className='col-md-4'>
                    <strong>N° Alumnos</strong> <span className='estilocampo'>{TotalFilteredRowCount}</span>                          
                         {PorcEstuPerdidos && 
                            <span className='estilocampo'> el %{PorcEstuPerdidos} de la Población</span>
                          }
                    </div>
                </div>

                <div className="ag-theme-alpine" style={{height: '80vh', width: '100%'}}>
                     
                     <AgGridReact
                         ref={gridRef}
                         localeText={localeText}
                         rowData={ListadoAlumnos}
                         defaultColDef={defaultColDef}
                         rowSelection={'multiple'}
                         
                  /*        isRowSelectable={isRowSelectable}  */
                         onSelectionChanged={onSelectionChanged}
                         onRowSelected
                         columnDefs={columnDefs}
                       /*   onFirstDataRendered={onFirstDataRendered} */
                       >
                     </AgGridReact>
                 </div>

                   {/*  <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Grado</th>
                            <th scope="col">Grupo</th>
                            <th scope="col" style={{width:'30%'}}>Alumno</th>
                            <th scope="col" style={{width:'7%'}}>N° Perdi.</th>
                            <th scope="col">Asignaturas</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnos.map((alumno, index) => 
                            <tr key={index}>
                                <td>{alumno.nombre_grado}</td>
                                <td>0{alumno.id_grupo}</td>
                                <td>{alumno.nombres_apellidos_al}</td>
                                <td align='center'>{alumno.asignaturas_perdidas}</td>
                                <td>{alumno.asignaturas_con_nota_baja}</td>                                
                            </tr>
                        )}    
                        </tbody>
                        </table> */}
                </div>
            </div>
        </section>
        </>
  )
}

export default AlumnosMas2AsigPerdidasPer