import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import InputNotaIndicador from '../components/InputNotaIndicador'
import InputNotaIndicadorLetra from '../components/InputNotaIndicadorLetra'
import FilaEditIndicador from '../components/FilaEditIndicador'
import { ToastContainer } from 'react-toastify';
import BotonTabla from '../components/BotonTabla'

const NotasIndicador = () => {

    const [ListaGados, setListaGados] = useState([]) 
    const [ListaGrupos, setListaGrupos] = useState([]) 
    const [ListaAsignatura, setListaAsignatura] = useState([]) 
    const [ListaIndicadores, setListaIndicadores] = useState([]) 
    const [ListaAlumnos, setListaAlumnos] = useState([]) 
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)
    const [IdDocente, setIdDocente] = useState(0)
    const [Periodo, setPeriodo] = useState(0)
    const [ToPeriodo, setToPeriodo] = useState(0)
    const [Asignatura, setAsginatura] = useState(null)
    const [Indicador, setIndicador] = useState(null)
    const [NombreGrado, setNombreGrado] = useState("")
    const [Loading, setLoading] = useState(false)
    const [LoadingAs, setLoadingAs] = useState(false)
    const [Loadingindc, setLoadingindc] = useState(false)
    const [AsigComport, setAsigComport] = useState(false)
    const [ActulizarTabla, setActulizarTabla] = useState(false)
    const [ListaTodosIndicadores, setListaTodosIndicadores] = useState([])



    useEffect(() => {
        GetData('periodos/actual/', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setToPeriodo(parseInt(result.periodo))
            }
        })
        
        GetData('grados/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGados(result.grados)
            }
        })
        GetData('grupos/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGrupos(result.grupos)
            }
        })


 
    }, []) 

    useEffect(() => {
        setListaAsignatura([])
        setLoadingAs(true)
        setListaIndicadores([])
        GetData('asignaturas/asignaturasgrado/'+Grado, null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setLoadingAs(false)
                setListaAsignatura(result.asignaturas)
                const gradoEncontrado = ListaGados.find((grado) => grado.id_grados === Grado);
                if (gradoEncontrado)
                    setNombreGrado(gradoEncontrado.nombre_grado)
                else
                    setNombreGrado("")
            }else{
                setLoadingAs(false)
            }
        })
 
      
    }, [Grado])


    useEffect(() => {
        setLoadingindc(true)
        GetData('indicadores/indicadoresasignatura/'+Grado+'/'+Grupo+'/'+Asignatura+'/'+Periodo, null).then ((result) =>{
            setIndicador(null)
            if (result.error === false){
                setListaIndicadores(result.indicadores)                
                setLoadingindc(false)

                setIdDocente(0)
                if (Grado && Grupo && Asignatura){
                    GetData('asignaciones/getiddocente/'+Grado+'/'+Grupo+'/'+Asignatura, null).then((result) => {
                        if (result.error === false)
                            setIdDocente(result.id_docente)            
                    })
                }
            }
        });
           
      

    }, [Grado, Grupo, Asignatura, Periodo, ActulizarTabla])

    useEffect(() => {
        const fetchData = async () => {
            const datos = {
                id_grado: Grado,
                id_grupo: Grupo,
                id_asignatura: Asignatura,
                id_periodo: Periodo,
                id_indicador: Indicador
            };
            
            try {
                const result = await GetData('institucion/asigcomportamiento/', null);
                let responseJSON = result;
                setAsigComport(false);
                if (responseJSON.error === false) {
                    if (parseInt(result.id_asignatura) === parseInt(Asignatura)) {
                        setAsigComport(true);
                    }
                }
                
                setListaAlumnos([]);
                if (!isNaN(Indicador)) {
                    setLoading(true);
                    const alumnosResult = await PostData('calificaciones/alumnosadmin/', datos);
                    if (alumnosResult.error === false) {
                        setListaAlumnos(alumnosResult.alumnos);
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
    
        fetchData();
    }, [Indicador]);
    
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Evita que se envíe el formulario por defecto
          const form = e.target.form;
          const index = Array.prototype.indexOf.call(form, e.target);
          form.elements[index + 1].focus();
        }
    }
    
    const SeleccionarIndicador = async () => {
        try {
    
            setListaTodosIndicadores([])
            const result = await GetData('indicadores/listaindicadoresgra2/' + Grado + '/' + Asignatura + '/' + IdDocente, null);
    
            if (result.error === false) {
                setListaTodosIndicadores(result.indicadores);
    
                // Mover la lógica de apertura del modal aquí
                const modal = document.getElementById('modalIndicadores');
    
                // Verificar si el modal existe antes de intentar abrirlo
                if (modal) {
                    // Usar el método modal() para abrir el modal
                    const modalInstancia = new window.bootstrap.Modal(modal);
                    modalInstancia.show();
                }
            } else {
                console.error("Error al obtener los indicadores:", result.message);
                // Manejo adicional de errores según sea necesario
            }
        } catch (error) {
            console.error("Error al realizar la solicitud:", error);
            // Manejo adicional de errores según sea necesario
        }
    }

  return (
    <>
         <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Modificar Notas de Indicador</div>    
                <div className="card-body pb-0">

                    <div className="row mb-2">
                            <div className='col-md-2'>
                                        <span className='label'>Grado: </span>
                                        <select className="form-select form-select-sm" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGados.map(tipo => {
                                                    return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                            })}
                                        </select> 
                                      
                            </div>    
                            <div className='col-md-1'>
                                        <span className='label'>Grupo: </span>
                                        <select className="form-select form-select-sm" aria-label="Default select example" value={Grupo} onChange={(e) => setGrupo(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                      
                            </div>   
                            <div className='col-md-1'>
                                        <span className='label'>Período: </span>
                                        <select className="form-select form-select-sm" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)} >
                                        {[...Array(ToPeriodo)].map((_, index) => (
                                            <option key={index + 1} value={index + 1}>{index + 1}</option>
                                        ))}
                                        </select> 
                                      
                            </div>   

                            <div className='col-md-3'>
                                <span className='label'>Asignaturas: </span>
                                <select className="form-select form-select-sm" aria-label="Default select example" value={Asignatura} onChange={(e) => setAsginatura(e.target.value)} disabled={LoadingAs} >
                                <option defaultValue={null}>{LoadingAs ? <span>Buscando...</span> : <span>Seleccionar</span> }</option>
                                {ListaAsignatura.map(tipo => {
                                            return <option key={tipo.id_asignatura} value={tipo.id_asignatura}>{tipo.nombre_asignatura}</option>
                                    })}
                                </select> 
                            </div>

                            <div className='col-md-5'>
                                <span className='label'>indicadores: </span>
                                <div className='d-flex align-items-center'>
                                <select className="form-select form-select-sm" aria-label="Default select example" value={Indicador} onChange={(e) => setIndicador(e.target.value)} disabled={LoadingAs} >
                                <option value={null}>{Loadingindc ? <span>Buscando...</span> : <span>Seleccionar</span> }</option>
                                {ListaIndicadores.map(indic => {
                                            return <option key={indic.id_indicador} value={indic.id_indicador}>{indic.codigo_indic} - {indic.descripcion_indic}</option>
                                    })}
                                </select> 
                                <BotonTabla icono='indicador.png' titulo='Agregar Indicador' evento={()=>SeleccionarIndicador()} />
                                </div>
                            </div>

                    </div>
                    <div className='row'>
                    {Loading && 
                    <div class="progress mt-3">
                        <div class="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <form>
                    <table class="table table-striped  mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombres</th>
                            <th scope="col">Nota</th>
                        </tr>
                        </thead>
                        <tbody> 
                            {ListaAlumnos.map((al, index) => 
                            <tr>
                                <td >{al.id_matricula}</td>
                                <td >{al.nombres_apellidos_al}</td>
                                <td style={{width:'5%'}}>    
                                   {AsigComport ?  
                                    <InputNotaIndicadorLetra
                                        id_matricula={al.id_matricula}
                                        id_grado={Grado}
                                        id_grupo={Grupo}
                                        id_asignatura={Asignatura}
                                        id_periodo={Periodo}
                                        id_indicador={Indicador}
                                        notav={al.notav}
                                        handleKeyDown={handleKeyDown}
                                    />
                                    :
                                    <InputNotaIndicador
                                        id_matricula={al.id_matricula}
                                        id_grado={Grado}
                                        id_grupo={Grupo}
                                        id_asignatura={Asignatura}
                                        id_periodo={Periodo}
                                        id_indicador={Indicador}
                                        notav={al.notav}
                                        handleKeyDown={handleKeyDown}
                                    />
                                    }
                                </td>
                            </tr>
                            )}        
                        </tbody>
                        </table>
                        </form>
                    </div>
                </div>
            </div>
        </section>        

        <div className="modal fade" id="modalIndicadores" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                            <i className="bi bi-pencil-square"></i> Lista de Indicadores </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                    <div className='row'>

                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Código</th>
                                <th scope="col">Concepto</th>
                                <th scope="col">Período</th>
                                <th scope="col">Op</th>
                            </tr>
                            </thead>
                            <tbody>
                                {ListaTodosIndicadores.map((indic,index) => 
                                    <FilaEditIndicador id_indicador = {indic.id_indicador} 
                                                        codigo_indic={indic.codigo_indic} 
                                                        id_periodo={indic.id_periodo} 
                                                        descripcion_indic={indic.descripcion_indic} 
                                                        grado={Grado} 
                                                        asig={Asignatura} 
                                                        id_docente={IdDocente}
                                                        ActulizarTabla={ActulizarTabla}
                                                        setActulizarTabla={setActulizarTabla} />
                                    
                                    )}  
                            </tbody>
                            </table>
                    </div>

                    </div>
                </div>
                </div>
        </div>
        <ToastContainer />
    </>
  )
}

export default NotasIndicador