import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { useParams } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { URL_INFORMES } from '../services/UrlWebServis';
import { getToken, getSede } from '../services/Usuario';

const Consolidados = () => {
    
    const { grado, grupo } = useParams();
     
    const [datoGrado, setdatoGrado] = useState([])
    const [Periodo, setPeriodo] = useState(0)
    const [listaConsolidado, setlistaConsolidado] = useState([])
    const [listaAsignaturas, setlistaAsignaturas] = useState([])
    const [LoadingTabla, setLoadingTabla] = useState(false)

  useEffect(() => {

        GetData('periodos/actual/', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPeriodo(result)
            }
        })
        GetData('grados/infogrado/'+grado+'/'+grupo, null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setdatoGrado(result)
            }
        })
        setLoadingTabla(true)
        GetData('/informespdf/consolidadperiodo/'+grado+'/'+grupo+'/1', null).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                setLoadingTabla(false)
                setlistaConsolidado(result.datos)
                setlistaAsignaturas(result.materias)
            }
        })



  }, [])
     
  function generarNombreCorto(asignatura) {
    
    let  palabras = asignatura.replace(/\s+e\s+/ig, ' ').replace(/\s+y\s+/ig, ' '); //se remueven la e o la y del medio
    
    palabras = asignatura.split(' ');

    if (palabras.length === 1){
        return asignatura.substring(0, 3).toUpperCase()
    }else{
        return palabras[0].substring(0, 3).toUpperCase() + palabras[1].substring(0, 1).toUpperCase()
    }
  
  }
  

  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Consolidado</div>    
                
                <div className="card-body pb-0">

                          <div className='row'>
                            <div className='col-md-4' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{datoGrado.nom_grado} - 0{grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{listaConsolidado.length}</span>                                
                            </div> 
                            <div className='col-md-5'>
                            <div className="btn-group mt-3" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                Seleccione un Consolidado
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_nive.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período y Nivelación</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_puesto.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Por Período y Puesto</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_sum.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Período Acumulado Total</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_prom.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Promedio por Período</a></li>
                                <li><a className="dropdown-item" target="_blank"  href={URL_INFORMES + `consolidados_excelpro.php?grado=${grado}&grupo=${grupo}&periodo=${Periodo.periodo}&doc=0&t=${getToken()}&db=${getSede()}`}>Excel Calculo Promedio</a></li>
                                </ul>
                            </div>
                            </div>
                        </div>                               

                        {LoadingTabla && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }  

                        <table className="table table-striped table-hover mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombre del Alumno</th>
                            {listaAsignaturas.map((mat, index) => (
                                        <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            {mat}
                                        </Tooltip>
                                        }
                                    >
                                 <th scope="col">{generarNombreCorto(mat)}</th>
                                 </OverlayTrigger>
                            ))}
                            <th>Prom</th>
                        </tr>
                        </thead>
                        <tbody>
                            {listaConsolidado.map((datos, index) => (
                            <tr key={index}>
                                {datos.map((nota, colIndex) => (
                                    <td key={colIndex} style={{ textAlign: colIndex >= 2 ? 'center' : 'left' }}>
                                     {nota < 3 ? <span className='nota_perdida'>{nota}</span> : nota}
                                    </td>
                                ))}
                            </tr>
                            ))}

                        </tbody>
                        </table>
                </div>
                
            </div>
        </section>

    </>
  )
}

export default Consolidados