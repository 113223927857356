import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'
import { getSede, getToken } from '../services/Usuario'
import config from '../config'

function Desempenios() {

    const [ListaTiposUS, setListaTiposUS] = useState([]) 
    const [ListaGados, setListaGados] = useState([]) 
    const [ListaDocentes, setListaDocentes] = useState([]) 
    const [ListaAsignatura, setListaAsignatura] = useState([]) 
    const [ListaIndicadores, setListaIndicadores] = useState([]) 
    const [Loading, setLoading] = useState(false)
    const [LoadingAs, setLoadingAs] = useState(true)
    const [LoadingTabla, setLoadingTabla] = useState(false)
    const [Grado, setGrado] = useState(null)
    const [NombreGrado, setNombreGrado] = useState("")
    const [Asignatura, setAsginatura] = useState(null)
    const [NombAsignatura, setNombAsginatura] = useState("")
    const [Periodo, setPeriodo] = useState(1)
    const [ModCodigo, setModCodigo] = useState("0")
    const [ModPeriodo, setModPeriodo] = useState("0")
    const [ModDescripcion, setModDescripcion] = useState("1")
    const [ModIdDesemp, setModIdDesemp] = useState(0)
 
    useEffect(() => {
        setNombAsginatura("")
        setNombreGrado("")
        GetData('grados/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGados(result.grados)
            }
        })

        GetData('docentes/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaDocentes(result.docentes)
            }
        })

       
    }, []) 
    
    useEffect(() => {
        setListaAsignatura([])
        setLoadingAs(true)
        setListaIndicadores([])
        GetData('asignaturas/asignaturasgrado/'+Grado, null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setLoadingAs(false)
                setListaAsignatura(result.asignaturas)
                setAsginatura(null)
                const gradoEncontrado = ListaGados.find((grado) => grado.id_grados === Grado);
                if (gradoEncontrado)
                    setNombreGrado(gradoEncontrado.nombre_grado)
                else
                    setNombreGrado("")
            }else{
                setLoadingAs(false)
            }
        })
 
      
    }, [Grado])
    
    


    const buscarindicadores = () => {    
        
        if (Grado && Asignatura){
            setLoadingTabla(false)
            setListaIndicadores([])
            if (Grado){
                setLoadingTabla(true)
                GetData('indicadores/' + Grado + '/' + Asignatura, null).then ((result) =>{
                    let responseJSON = result;
            
                    if (responseJSON.error === false){
                        setListaIndicadores(result.indicadores)
                        setLoadingTabla(false)
                        const asigEnc = ListaAsignatura.find((grado) => grado.id_asignatura === Asignatura);
                        if (asigEnc)
                            setNombAsginatura(asigEnc.nombre_asignatura)
                        else
                            setNombAsginatura("")
                        return true;
                    }
                })
            }else{
                setLoadingTabla(false)
            }
        }else{
            setLoadingTabla(false)
        }
    }

    const Modificar = (des) => {
          setModIdDesemp(des.id_indicador)
          setModCodigo(des.codigo_indic)
          setModPeriodo(des.id_periodo)  
          setModDescripcion(des.descripcion_indic)  
          const modal = document.getElementById('modalD');

          // Verificar si el modal existe antes de intentar abrirlo
          if (modal) {
            // Usar el método modal() para abrir el modal
            const modalInstancia = new window.bootstrap.Modal(modal);
            modalInstancia.show();
          }
    }

    const Nuevo = () => {
          setModIdDesemp(0)
          setModCodigo("")
          setModPeriodo(1)  
          setModDescripcion("")  
          const modal = document.getElementById('modalD');

          // Verificar si el modal existe antes de intentar abrirlo
          if (modal) {
            // Usar el método modal() para abrir el modal
            const modalInstancia = new window.bootstrap.Modal(modal);
            modalInstancia.show();
          }
    }

    const Guardar = () => {
        
        const datos = {
            id_indicador: ModIdDesemp ? ModIdDesemp : 0,
            codigo_indic: ModCodigo,
            descripcion_indic: ModDescripcion,
            id_grado: Grado,
            id_asigantura: Asignatura,
            id_periodo: ModPeriodo
           
        }
          
        PostData('indicadores/adminsave', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Desepeño Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  buscarindicadores();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const confirmarEliminacion = (id_indicador, codigo) => {
        Swal.fire({
          title: '¿Estás seguro?',
          text: 'Esta acción eliminará el indicador: ' + codigo,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            // Si el usuario confirma, realiza la eliminación
            BorrarDes(id_indicador);
          }
        });
      };

    const BorrarDes = (id_indicador) => {
        
        const datos = {
            id_indicador: id_indicador
        }
          
        PostData('indicadores/deleteadmin', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Desepeño Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  buscarindicadores();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const Descargar = () => {
        const url_informe = config.API_URL_INFORMES;
        const url = url_informe + '/descarga_indicadores_xls.php?id_grado='+Grado+'&token='+getToken()+'&db='+getSede()
        window.open(url, "_blank");   
    }

  return (
    <>
     <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Desempeños</div>    
            <div className="card-body pb-0">


                <div className="formulario">
                    <form method="post" >
                        <div className='row'>
                            <div className='col-md-2'>
                                <strong>Grado: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                <option defaultValue={null}>Seleccionar</option>
                                {ListaGados.map(tipo => {
                                            return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                    })}
                                </select> 
                            </div>                               
                            <div className='col-md-3'>
                                <strong>Asignaturas: </strong>
                                <select className="form-select  form-select-sm estilocampo" aria-label="Default select example" value={Asignatura} onChange={(e) => setAsginatura(e.target.value)} disabled={LoadingAs} >
                                <option defaultValue={null}>{LoadingAs ? <span>Buscando...</span> : <span>Seleccionar</span> }</option>
                                {ListaAsignatura.map(tipo => {
                                            return <option key={tipo.id_asignatura} value={tipo.id_asignatura}>{tipo.nombre_asignatura}</option>
                                    })}
                                </select> 
                            </div>

                            <div className='col-md-6'>
                                <button type="button" className="btn btn-info btn-sm mt-4" onClick={buscarindicadores} disabled={Asignatura===null}>
                                        <i className="bi bi-search"></i>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={Nuevo} disabled={Asignatura===null} >
                                       Nuevo Desempeño
                                </button>
                                <button type="button" className="btn btn-success btn-sm mt-4 ml-2" onClick={Descargar} disabled={Asignatura===null} >
                                       Descargar
                                </button>
                            </div>  
                        </div>
                    </form>
                </div>
                <div className="card-body pb-0">
                    <h5 className="card-title">Lista Desempeños - <span>Grado:</span> {NombreGrado}  <span>Asignatura:</span> {NombAsignatura}  
                    </h5>

                    <div className='row'>
                       
                    </div>
                    {LoadingTabla && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">N° Notas</th>
                            <th scope="col" style={{width:'15%'}}>OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaIndicadores.map((us, index) => 
                            <tr key={us.codigo_indic}>
                                <th scope="row">{us.codigo_indic}</th>
                                <td>{us.descripcion_indic}</td>
                                <td>{us.cant}</td>
                                <td>                                       
                                    <BotonTabla icono='lapiz.png' titulo='Editar Indicador' evento={()=>Modificar(us)} />
                                    <BotonTabla icono='borrar.png' titulo='Borrar Indicador' evento={()=>confirmarEliminacion(us.id_indicador, us.codigo_indic)} />
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
            </div>
        </section>        


        <div className="modal fade" id="modalD" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Editar Desempeño</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <strong>Código: </strong>
                                <input type="text" className="form-control" id="nombres" name="nombres" value={ModCodigo} onChange={(e) => setModCodigo(e.target.value)} />
                            </div>     
                        </div>
                       {/*  <div className='row'>
                            <div className='col-md-6'>
                               <strong>Periodos: </strong>
                                <select className="form-select sm" aria-label="Default select example" value={ModPeriodo} onChange={(e) => setModPeriodo(e.target.value)} >
                                    <option defaultValue={1}>Período 1</option>
                                    <option value={2}>Período 2</option>
                                    <option value={3}>Período 3</option>
                                    <option value={4}>Período 4</option>                               
                                </select> 
                            </div>     
                        </div> */}
                        <div className='row'>
                                <div className='col-md-12'>
                                <strong>Descripción</strong>
                                <textarea className="form-control" placeholder="Digite la descripción" id="descripcion" style={{height: '200px'}}
                                value={ModDescripcion} onChange={(e) => setModDescripcion(e.target.value)}
                                ></textarea>
                                </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary" onClick={Guardar} disabled={ModCodigo === "" || ModDescripcion === ""}>Guardar</button>
                    </div>
                  </div>
                </div>
              </div>
    </>
  )
}

export default Desempenios