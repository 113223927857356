import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import Grados from './GradosHome/Grados'

const HomePrincipal = () => {
    const [NumEstud, setNumEstud] = useState(0)
    const [NumRestir, setNumRestir] = useState(0)
    const [NumDoc, setNumDoc] = useState(0)
    const [Periodo, setPeriodo] = useState(0)
    const [FechaCierre, setFechaCierre] = useState(null)
    const [TiempoRestante, setTiempoRestante] = useState("")

     useEffect(() => {
        GetData('matriculas/numestudiantes', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setNumEstud(result.numestudiantes)
                localStorage.setItem('numestudiantes_cand', result.numestudiantes)
                setNumRestir(result.num_retirados)
                setNumDoc(result.num_docentes)               
            }
        })
        GetData('periodos/actual', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPeriodo(result.periodo)
                setFechaCierre(result.fecha_formateada)
                setTiempoRestante(result.t_restante)
                
            }
        })
    }, []) 
    

  return (
    <>
        <section className="section dashboard">
            <div className="row">
        
            
            <div className="col-lg-12">
                <div className="row">
        
            
                <div className="col-xxl-3 col-md-3">
                    <div className="card info-card sales-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">Alumnos <span>| Matriculados Activos</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-people"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumEstud}</h6>
                           {/*  <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
        
                {/* <!-- Revenue Card --> */}
                <div className="col-xxl-3 col-md-3">
                    <div className="card info-card revenue-card">
        
        
                    <div className="card-body">
                        <h5 className="card-title">Alumnos <span>| Retirados</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-dash"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumRestir}</h6>
                           {/*  <span className="text-success small pt-1 fw-bold">8%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
        
                        </div>
                        </div>
                    </div>
        
                    </div>
                </div>
                {/* <!-- End Revenue Card --> */}
        
                {/*  <!-- Customers Card --> */}
                <div className="col-xxl-3 col-md-3">
        
                    <div className="card info-card customers-card">
        
                   
                    <div className="card-body">
                        <h5 className="card-title">Docentes <span></span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-person-badge"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{NumDoc}</h6>
                           {/*  <span className="text-danger small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">decrease</span> */}
        
                        </div>
                        </div>
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                <div className="col-xxl-3 col-md-3">
        
                    <div className="card activo-card customers-card">
        
                    
        
                    <div className="card-body">
                        <h5 className="card-title">Período <span>| Actual</span></h5>
        
                        <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                            <i className="bi bi-stopwatch"></i>
                        </div>
                        <div className="ps-3">
                            <h6>{Periodo}</h6>
                            <span className="text-danger small pt-1 fw-bold">{FechaCierre}</span> <span className="text-muted small pt-2 ps-1">{TiempoRestante}</span>
        
                        </div>
                        </div>
                        
        
                    </div>
                    
                    </div>
        
                </div>
                {/* <!-- End Customers Card --> */}

                {/*  <!-- Customers Card --> */}
                
                {/* <!-- End Customers Card --> */}
                <div className="row">
                    <Grados Periodo={Periodo} />
                </div>
           
                    {/* <!-- End sidebar recent posts--> */}
        
             
                {/* <!-- End News & Updates --> */}
        
            </div>
            {/* <!-- End Right side columns --> */}
        
            </div>
            </div>
        </section>
  </>
  )
}

export default HomePrincipal