import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import { validarEmail, calculateEdad } from '../components/utils';
import Swal from 'sweetalert2'
import MensajeError from '../components/MensajeError';
import BotonTabla from '../components/BotonTabla';
import config from '../config';
import { getSede, getToken } from '../services/Usuario';


const Matriculas = () => {

    const [ListaMatriculas, setListaMatriculas] = useState([])
    const [ListaGados, setListaGados] = useState([])
    const [ListaGrupos, setListaGrupos] = useState([])
    const [ListaEstados, setListaEstados] = useState([])
    const [TiposIdentificacion, setTiposIdentificacion] = useState([])
    const [ListaMunicipios, setListaMunicipios] = useState([])
    const [ListaTSanguineo, setListaTSanguineo] = useState([])
    const [ListaJornadas, setListaJornadas] = useState([])
    const [NombreGrado, setNombreGrado] = useState("")
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)    
    
    const [Loading, setLoading] = useState(false)    
    const [LoadingTabla, setLoadingTabla] = useState(false)    
    const [LoadingForm, setLoadingForm] = useState(false)    

    const [ModNMatricula, setModNMatricula] = useState(0)
    const [TIdMatricula, setTIdMatricula] = useState(0)
    const [ModIdMatricula, setModIdMatricula] = useState(0)
    const [ModIdAlumno, setModIdAlumno] = useState(0)
    const [NombreAlumno, setNombreAlumno] = useState('')
    const [Identificacion, setIdentificacion] = useState('')
    const [GradoM, setGradoM] = useState(null)
    const [GrupoM, setGrupoM] = useState(null)    
    const [JornadaM, setJornadaM] = useState(null)    
    const [EstadoM, setEstadoM] = useState(null)    
    const [FechaNacM, setFechaNacM] = useState(null)    
    const [Edad, setEdad] = useState(0)    
    const [TelefonoM, setTelefonoM] = useState(null)    
    const [DireccionM, setDireccionM] = useState(null)    
    const [TipoIdent, setTipoIdent] = useState(null)    
    const [Municipio, setMunicipio] = useState(null)    
    const [TipoSangre, setTipoSangre] = useState(null)    
    const [SexoM, setSexoM] = useState(null)    
    const [EmailM, setEmailM] = useState(null)    
    const [emailValido, setEmailValido] = useState(true);
    
    const [SisbenM, setSisbenM] = useState('');
    const [EntidadSM, setEntidadSM] = useState('');

    const [IdPadre, setIdPadre] = useState(0);
    const [NombresPadre, setNombresPadre] = useState('');
    const [TelefonoPadreM, setTelefonoPadreM] = useState('');
    const [IdentificacionPadreM, setIdentificacionPadreM] = useState('');
    const [OcupacionPadreM, setOcupacionPadreM] = useState('');
    const [IdMadre, setIdMadre] = useState(0);
    const [NombresMadre, setNombresMadre] = useState('');
    const [TelefonoMadreM, setTelefonoMadreM] = useState('');
    const [IdentificacionMadreM, setIdentificacionMadreM] = useState('');
    const [OcupacionMadreM, setOcupacionMadreM] = useState('');
    const [IdAcudiente, setIdAcudiente] = useState(0);
    const [NombresAcudiente, setNombreAcudiente] = useState('');
    const [TelefonoAcudM, setTelefonoAcudM] = useState('');
    const [IdentificacionAcudM, setIdentificacionAcudM] = useState('');
    const [OcupacionAcuM, setOcupacionAcudM] = useState('');
    const [ParentescoAc, setParentescoAc] = useState('');
    const [ListadoParentescos, setListadoParentescos] = useState([]);
    
    const [IsPadre, setIsPadre] = useState(true);
    const [IsMadre, setIsMadre] = useState(false);
    const [IsAcudiente, setIsAcudiente] = useState(false);
    const [SedeTraslado, setSedeTraslado] = useState(null);

    const sedes = [
        { value: 'default', label: 'Principal' },
        { value: 'dbprado', label: 'Prado' },
        { value: 'dbsmartin', label: 'San Francísco' }
      ];
      

    // Filtrar la sede actual de la lista de opciones
    const opcionesSedes = sedes.filter(sede => sede.value !== getSede());
    
    console.log(opcionesSedes, getSede())

    useEffect(() => {
        GetData('grados/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGados(result.grados)
            }
        })
        GetData('grupos/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGrupos(result.grupos)
            }
        })

        GetData('matriculas/estadosmatricula', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaEstados(result.estados)
            }
        })
        
        GetData('matriculas/tipoidentificacion', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setTiposIdentificacion(result.tipos)
            }
        })

        GetData('matriculas/listamunicipios', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaMunicipios(result.municipios)
            }
        })

        GetData('matriculas/tiposanguineo', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaTSanguineo(result.tsanguineo)
            }
        })

        GetData('matriculas/listajornadas', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaJornadas(result.jornadas)
            }
        })

        GetData('matriculas/listadoparentescos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListadoParentescos(result.datos)
            }
        })
       
 
    }, []) 

    useEffect(() => {
        setEmailValido(validarEmail(EmailM));
    }, [EmailM])

   

   const onChangeIsAcudiente = (parentesco) => {
         setIsPadre(false)
         setIsMadre(false)
         setIsAcudiente(false)
         
        if (parentesco === "Padre") {
            setIsPadre(true);
        } else if (parentesco === "Madre") {
            setIsMadre(true);
        } else {
            setIsAcudiente(true);
        }

   }
    

    //obtiene el nombre del grado buscado
    const gradonombre = () => {
        const gradoEncontrado = ListaGados.find((grado) => grado.id_grados === Grado);
        
        if (gradoEncontrado)
            setNombreGrado(gradoEncontrado.nombre_grado)
        else
            setNombreGrado("")
    }

    const buscarMatriculas = () => {
        //setListaMatriculas([])
        if (Grado && Grupo){
            setLoading(true)
            const datos = {
                id_grado: Grado,
                id_grupo: Grupo
            }
            setLoadingTabla(true)
            PostData('matriculas/listaalumnosgrado/', datos).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaMatriculas(result.alumnos)
                    gradonombre()
                    setLoading(false)
                    setLoadingTabla(false)
                    return true;
                }
            })
        }
    } 

    const onchangeFechaNac = (e) => {
        setFechaNacM(e.target.value)
        setEdad(calculateEdad(e.target.value))
    }

    const SeleccionarParentesco = (parentesco) => {
        setParentescoAc(parentesco);
    }

    const buscarIndentificacionAlumno = () => {
        


        if (Identificacion){
            setLoading(true)
            const datos = {
                cedula: Identificacion
            }

            PostData('matriculas/buscaralumnocedula/', datos).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    const datosm = result.alumno;
                    const mat = result.matricula;

                    if (mat !== false){
                        Swal.fire({
                            title: 'Error al Guardar',
                            text: mat,
                            icon: 'error',
                            confirmButtonText: 'Aceptar'
                        });
                    }else{
                        setModIdAlumno(datosm.id_alumnos)
                        setNombreAlumno(datosm.nombres_apellidos_al)
                        setFechaNacM(datosm.fecha_nacimiento_al)
                        setTelefonoM(datosm.telefono_al)
                        setDireccionM(datosm.direccion_al)
                        setTipoIdent(datosm.id_tipo_documento)
                        setMunicipio(datosm.id_lugar_expedicion)
                        setTipoSangre(datosm.tipo_sangre)
                        setSexoM(datosm.sexo)
                        setEmailM(datosm.email_al)
                        setEdad(calculateEdad(datosm.fecha_nacimiento_al))
                        setEntidadSM(datosm.seguro_alumno)
                        setLoading(false)
                        return true;
                    }
                }else{
                    setModIdAlumno(0)
                    setNombreAlumno("")
                    setFechaNacM("")
                    setTelefonoM("")
                    setDireccionM("")
                    setTipoIdent(null)
                    setMunicipio(null)
                    setTipoSangre(null)
                    setSexoM("M")
                    setEmailM("")
                    setEdad(0)
                    setEntidadSM("")
                }
            })
        }
    }

    const Nuevo = () => {

        setModNMatricula(0)
        setModIdMatricula(0)
        setModIdAlumno(0)
        setNombreAlumno("")
        setIdentificacion("")
        setGradoM("")
        setGrupoM("")
        setJornadaM("")
        setEstadoM(0) 
        setFechaNacM("")
        setTelefonoM("")
        setDireccionM("")
        setTipoIdent("0")
        setMunicipio("0")
        setTipoSangre("")
        setSexoM("m")
        setEmailM("")
        setEdad(0)
        setIsPadre(false)
        setIsMadre(false)
        setIsAcudiente(false)
        setSisbenM("")
        setEntidadSM("")


        setNombresPadre("")
        setTelefonoPadreM("")
        setIdentificacionPadreM("")
        setOcupacionPadreM("")

        setNombresMadre("")
        setTelefonoMadreM("")
        setIdentificacionMadreM("")
        setOcupacionMadreM("")

        setNombreAcudiente("")
        setTelefonoAcudM("")
        setIdentificacionAcudM("")
        setOcupacionAcudM("")
        setParentescoAc("")

        setIdPadre(0)
        setIdMadre(0)
        setIdAcudiente(0)

        setGradoM(Grado)
        setGrupoM(Grupo)

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }
  
    const Modificar = (dato) => {
        
        setLoadingForm(true)
        GetData('matriculas/datosmatricula/' + dato.id_matricula, null).then ((result) =>{
            let responseJSON = result;
            
            if (responseJSON.error === false){                
                const datosm = result.matricula;
                
                setModNMatricula(datosm.num_matricula)
                setModIdMatricula(datosm.id_matricula)
                setModIdAlumno(datosm.id_alumnos)
                setNombreAlumno(datosm.nombres_apellidos_al)
                setIdentificacion(datosm.num_documento_al)
                setGradoM(datosm.cod_grado)
                setGrupoM(datosm.id_grupo)
                setJornadaM(datosm.id_jornada)
                setEstadoM(datosm.id_estado_matricula) 
                setFechaNacM(datosm.fecha_nacimiento_al)
                setTelefonoM(datosm.telefono_al)
                setDireccionM(datosm.direccion_al)
                setTipoIdent(datosm.id_tipo_documento)
                setMunicipio(datosm.id_lugar_expedicion)
                setTipoSangre(datosm.tipo_sangre)
                setSexoM(datosm.sexo)
                setEmailM(datosm.email_al)
                setEdad(calculateEdad(datosm.fecha_nacimiento_al))
                setEntidadSM(datosm.seguro_alumno)
                setSisbenM(datosm.sisben)
                setLoadingForm(false)

                setIsPadre(false)
                setIsMadre(false)
                setIsAcudiente(false)

                setIdPadre(0)
                setNombresPadre("")
                setTelefonoPadreM("")
                setIdentificacionPadreM("")
                setOcupacionPadreM("")

                setIdMadre(0)
                setNombresMadre("")
                setTelefonoMadreM("")
                setIdentificacionMadreM("")
                setOcupacionMadreM("")

                setIdAcudiente(0)
                setNombreAcudiente("")
                setTelefonoAcudM("")
                setIdentificacionAcudM("")
                setOcupacionAcudM("")
                setParentescoAc("")


                const datospadres = result.padres;

                datospadres.forEach(datop => {
                        if (datop.descrip_parentesco === "Padre"){                            
                            setIdPadre(datop.id_acudientes_alumno)
                            setNombresPadre(datop.nombre_apellidos_ac)
                            setTelefonoPadreM(datop.telefonos_ac)
                            setIdentificacionPadreM(datop.identificacion_acud)
                            setOcupacionPadreM(datop.ocupacion_ac)
                            if (datop.acudiente_ac === 'S')
                                setIsPadre(true)
                        }else{
                            if (datop.descrip_parentesco === "Madre"){
                                setIdMadre(datop.id_acudientes_alumno)
                                setNombresMadre(datop.nombre_apellidos_ac)
                                setTelefonoMadreM(datop.telefonos_ac)
                                setIdentificacionMadreM(datop.identificacion_acud)
                                setOcupacionMadreM(datop.ocupacion_ac)
                                if (datop.acudiente_ac === 'S')
                                    setIsMadre(true)
                            }else{
                                setIdAcudiente(datop.id_acudientes_alumno)
                                setNombreAcudiente(datop.nombre_apellidos_ac)
                                setTelefonoAcudM(datop.telefonos_ac)
                                setIdentificacionAcudM(datop.identificacion_acud)
                                setOcupacionAcudM(datop.ocupacion_ac)
                                setParentescoAc(datop.descrip_parentesco)
                                if (datop.acudiente_ac === 'S')
                                    setIsAcudiente(true)
                            }
                        }
                });
                

            }
        })

        

         

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const Eliminar = (id_matricula, nombre) => {

      Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('asignaciones/delete/' + id_matricula, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El elemento ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      ListaMatriculas();
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

  const Guardar = () => {
    
        // Verifica si Grado, Docente, Asignatura e IH son null o vacíos
        console.log(GradoM , GrupoM , JornadaM , EstadoM ,   TipoIdent , NombreAlumno , Identificacion, TelefonoM, Municipio , TipoSangre ,
             DireccionM, FechaNacM , Municipio , TipoIdent , SisbenM , EntidadSM )

        if (GradoM === null || GrupoM === null || JornadaM === null || EstadoM === null || 
                TipoIdent === null || NombreAlumno === "" || Identificacion === "" || TelefonoM === "" || Municipio === null || TipoSangre === null ||
                 DireccionM === "" || FechaNacM === "" || Municipio === "" || TipoIdent === "" || SisbenM === "" || EntidadSM === "" ) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe llenar todos los campos para poder guardar",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return; // Detiene la ejecución si falta algún parámetro
        }

        if (NombresPadre === "" && NombresMadre === "" && NombresAcudiente === ""){
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe llenar los datos de al menos uno de los Padres o del Acudiente para poder guardar",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        if (NombresPadre === '' && IsPadre === true){
            Swal.fire({
                title: 'Error al Guardar',
                text: "Estas asignando de acudiente al Padre y no tiene los datos digitados.",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        if (NombresMadre === '' && IsMadre === true){
            Swal.fire({
                title: 'Error al Guardar',
                text: "Estas asignando de acudiente a la Madre y no tiene los datos digitados.",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        
        if (NombresAcudiente === '' && IsAcudiente === true){
            Swal.fire({
                title: 'Error al Guardar',
                text: "Estás seleccionando un acudiente, pero aún no has ingresado sus datos.",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        if (!IsPadre && !IsMadre && !IsAcudiente){
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe asingar un acudiente, para eso de clic en el circulo al frente del nombre de los Padre o Acudiente",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        if (NombresPadre !== "" && (IdentificacionPadreM === "" || OcupacionPadreM === "" || TelefonoPadreM === "")) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe Completar todos los datos del Padre del alumno",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }


        if (NombresMadre !== "" && (IdentificacionMadreM === "" || OcupacionMadreM === "" || TelefonoMadreM === "")) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe Completar todos los datos de la Madre del alumno",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        if (NombresAcudiente !== "" && (IdentificacionAcudM === "" || OcupacionAcuM === "" || TelefonoAcudM === "")) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe Completar todos los datos del Acudiente del alumno",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }

        const datos = {
            id_matricula : ModIdMatricula, 
            id_alumno: ModIdAlumno,
            cod_grado : GradoM, 
            id_grupo : GrupoM,
            id_jornada: JornadaM,
            id_estado_matricula: EstadoM,
            nombres_apellidos_al: NombreAlumno.toUpperCase(), 
            num_documento_al: Identificacion,
            fecha_nacimiento_al: FechaNacM,
            telefono_al: TelefonoM,
            direccion_al: DireccionM.toUpperCase(), 
            id_tipo_documento: TipoIdent,
            id_lugar_expedicion: Municipio,
            tipo_sangre: TipoSangre,
            email_al: EmailM, 
            sexo: SexoM,
            sisben: SisbenM.toUpperCase(),
            seguro_alumno: EntidadSM.toUpperCase(),
            padres: [
                {
                    id_acudientes_alumno: IdPadre,
                    nombre_apellidos_ac: NombresPadre.toUpperCase(),
                    identificacion_acud: IdentificacionPadreM,
                    ocupacion_ac: OcupacionPadreM,
                    telefonos_ac: TelefonoPadreM,
                    descrip_parentesco: "Padre",
                    acudiente_ac: IsPadre ? 'S' : 'N'
                },
                {
                    id_acudientes_alumno: IdMadre,
                    nombre_apellidos_ac: NombresMadre.toUpperCase(),
                    identificacion_acud: IdentificacionMadreM,
                    ocupacion_ac: OcupacionMadreM,
                    telefonos_ac: TelefonoMadreM,
                    descrip_parentesco: "Madre",
                    acudiente_ac: IsMadre ? 'S' : 'N'
                },
                {
                    id_acudientes_alumno: IdAcudiente,
                    nombre_apellidos_ac: NombresAcudiente.toUpperCase(),
                    identificacion_acud: IdentificacionAcudM,
                    ocupacion_ac: OcupacionAcuM,
                    telefonos_ac: TelefonoAcudM,
                    descrip_parentesco: ParentescoAc,
                    acudiente_ac: IsAcudiente ? 'S' : 'N'
                }
            ]

        }

        PostData('matriculas/save', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Cargar Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });                  

                  setModIdMatricula(result.id_matricula)
                  setModNMatricula(result.num_matricula)
                  setModIdAlumno(result.id_alumno)
                 // Modificar(result)
                  buscarMatriculas()
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })

  }

  const Trasladar = (id_matricula, nombre) => {

    Swal.fire({
        title: '¿Estás seguro?',
        text: '¡No podrás revertir esto! ¿Estas seguro de trasladar al alumno a: ' + nombre + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, procedamos'
    }).then((result) => {
        if (result.isConfirmed) {

            
            setTIdMatricula(id_matricula)
            setNombreAlumno(nombre)
    
            const modal = document.getElementById('modalTraslado');

            // Verificar si el modal existe antes de intentar abrirlo
            if (modal) {
            // Usar el método modal() para abrir el modal
            const modalInstancia = new window.bootstrap.Modal(modal);
            modalInstancia.show();
            }
        }
    })

  }

  const ProcesarTraslado = () => {

    if (TIdMatricula === ""  || SedeTraslado === null) {
        Swal.fire({
            title: 'Error al Guardar',
            text: "Debe Completar todos los datos del Padre del alumno",
            icon: 'error',
            confirmButtonText: 'Aceptar'
        });
        return;
    }

    const datos = {
        id_matricula : TIdMatricula, 
        sede_destino: SedeTraslado,
    }

    PostData('traslados/trasladoalumno', datos).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){

            Swal.fire({
                title: 'Tralado Estudiante',
                text: result.mensaje,
                icon:"success",
                confirmButtonText: 'Aceptar'
              });              
        } else {
            Swal.fire({
                title: 'Tralado Estudiante',
                text: result.mensaje,
                icon:"error",
                confirmButtonText: 'Aceptar'
              });              
        }
    })
  }

  const Imprimir = () => {
            const url_informe = config.API_URL_INFORMES;
            const id_matricula = ModIdMatricula;
            const url = url_informe + '/hojamatricula.php?id_matricula='+id_matricula+'&token='+getToken()+'&db='+getSede()
            window.open(url, "_blank");    
  }
  const Constancia = () => {
            const url_informe = config.API_URL_INFORMES;
            const id_matricula = ModIdMatricula;
            const url = url_informe + '/constancia.php?id_matricula='+id_matricula+'&token='+getToken()+'&db='+getSede()
            window.open(url, "_blank");    
  }

  return (    
        <>           
            <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Matriculas</div>    
            <div className="card-body pb-0">

                <div className="formulario">
                    <form method="post" >
                        

                        <div className='row mt-3'>
                            <div className='col-md-2'>
                                <strong>Grado: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                <option defaultValue={null}>Seleccionar</option>
                                {ListaGados.map(tipo => {
                                            return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                    })}
                                </select> 
                            </div>                               
                            <div className='col-md-2'>
                                        <strong>Grupo: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grupo} onChange={(e) => setGrupo(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                      
                            </div>   

                            <div className='col-md-3'>
                                <button type="button" className="btn btn-info btn-sm mt-4" onClick={buscarMatriculas} disabled={Grado===null}>
                                        <i className="bi bi-search"></i>
                                </button>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={Nuevo} disabled={!Grado || !Grupo} >
                                    <i className="bi bi-person-plus"></i> Nueva Matricula
                                </button>
                            </div>  
                            <div className='col-md-5' style={{marginTop: '25px'}}>
                                <span className='ml-4 label'>Grado:</span> <span className='texto'>{NombreGrado} - 0{Grupo}</span>  
                                <span className='ml-4 label'>N° Alumnos: </span> <span className='texto'>{ListaMatriculas.length}</span>                                
                            </div>

                        </div>
                    </form>
                </div>
                
                   {/*  <h5 className="card-title">Lista de Alumnos - <span className='ml-4'>Grado:</span> {NombreGrado}  <span className='ml-4'>Grupo:</span> {Grupo}   <span className='ml-4'>Total Alumnos: </span> {ListaMatriculas.length}
                    </h5> */}

                    <hr />
                    {LoadingTabla && 
                    <div className="progress mt-1 mb-2">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">N° Matri.</th>
                            <th scope="col">N°</th>
                            <th scope="col">Identifiación</th>
                            <th scope="col">Nombre del Alumnos</th>
                            <th scope="col">Estado</th>
                            <th scope="col" style={{width:'15%'}}>OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaMatriculas.map((us, index) => 
                            <tr key={us.id_matricula}>
                                <td>{us.num_matricula}</td>
                                <td>{index+1}</td>
                                <td>{us.num_documento_al}</td>
                                <td>{us.nombres_apellidos_al}</td>
                                <td>
                                    <span className={parseInt(us.id_estado_matricula) === 1 ? 'badge bg-success' : 'badge bg-danger'}>
                                        {us.nombre_estado}
                                    </span>
                                    </td>
                                <td>    
                                                                  
                                    <BotonTabla icono='ver.png' titulo='Ver Matricula' evento={()=>Modificar(us)} />
                                    <BotonTabla icono='lapiz.png' titulo='Editar Matricula' evento={()=>Modificar(us)} />
                                    <BotonTabla icono='borrar.png' titulo='Borrar Matricula' evento={()=>Eliminar(us.id_matricula , us.nombres_apellidos_al)} />
                                    {parseInt(us.id_estado_matricula) !== 4 && 
                                        <BotonTabla icono='trasladar.png' titulo='Trasladar Alumno' evento={()=>Trasladar(us.id_matricula , us.nombres_apellidos_al)} />
                                    }
                                 
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               
            </div>
            </div>
        </section>      

        <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> {ModNMatricula === 0 ? <>Nueva Matricula</> : <>Editar Matricula N° {ModNMatricula}</>}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {LoadingForm && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        <div className='row'>

                            <div className='col-md-2'>
                                        <span className='label'>Grado: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={GradoM} onChange={(e) => setGradoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGados.map(tipo => {
                                                    return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                            })}
                                        </select> 
                                    
                            </div>    
                            <div className='col-md-2'>
                                        <span className='label'>Grupo: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={GrupoM} onChange={(e) => setGrupoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                    
                            </div>  
                            <div className='col-md-2'>
                                        <span className='label'>Jornada: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={JornadaM} onChange={(e) => setJornadaM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaJornadas.map(tipo => {
                                                    return <option key={tipo.id_jornadas} value={tipo.id_jornadas}>{tipo.jornada}</option>
                                            })}
                                        </select> 
                            </div>
                            <div className='col-md-2'>
                                        <span className='label'>Estado: </span>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={EstadoM} onChange={(e) => setEstadoM(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaEstados.map(tipo => {
                                                    return <option key={tipo.id_estado_matricula} value={tipo.id_estado_matricula}>{tipo.nombre_estado}</option>
                                            })}
                                        </select> 
                            </div>
                            <div className='col-md-2'>
                             {ModNMatricula === 0 && <span class="badge bg-info text-dark">Nuevo</span>}
                            </div>                
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <span className='label'>Nombre Alumno: </span> {!NombreAlumno && MensajeError('Debe llenar el campo')}
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombreAlumno} onChange={(e) => setNombreAlumno(e.target.value)} />
                            </div>    
                            <div className='col-md-2'>
                                <span className='label'>Tipo Ident: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={TipoIdent} onChange={(e) => setTipoIdent(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {TiposIdentificacion.map(tipo => {
                                                    return <option key={tipo.id_tipo_documento} value={tipo.id_tipo_documento}>{tipo.abreviatura_doc}</option>
                                            })}
                                        </select> 
                            </div>  
                            <div className='col-md-4'>
                                <span className='label'>Identificación: </span> {!Identificacion && MensajeError('* campo requerido')}
                                <input type="number"  className="form-control form-control-sm estilocampo" 
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                buscarIndentificacionAlumno();
                                            }
                                        }}
                                        id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>     
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <span className='label'>Lugar Exp: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Municipio} onChange={(e) => setMunicipio(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaMunicipios.map(tipo => {
                                                    return <option key={tipo.id_municipio} value={tipo.id_municipio}>{tipo.lugar}</option>
                                            })}
                                        </select> 
                            </div> 
                            <div className='col-md-3'>
                                <span className='label'>Fecha Nac: </span>
                                <input type="date" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={FechaNacM} onChange={(e) => onchangeFechaNac(e)} />
                            </div>     
                            <div className='col-md-1'>
                                <span className='label'>Edad: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="edad" name="edad" value={Edad} disabled  />
                            </div>
                            <div className='col-md-3'>
                                <span className='label'>Tipo Sangre: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={TipoSangre} onChange={(e) => setTipoSangre(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaTSanguineo.map(tipo => {
                                                    return <option key={tipo.tipo} value={tipo.tipo}>{tipo.tipo}</option>
                                            })}
                                        </select> 
                            </div> 
                          
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>Sexo: </span>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={SexoM} onChange={(e) => setSexoM(e.target.value)} >
                                        <option value='M'>Masculino</option>
                                        <option value='F'>Femenino</option>
                                </select>
                            </div>
                             <div className='col-md-3'>
                                <span className='label'>Celular: </span>
                                <input type="number" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoM} onChange={(e) => setTelefonoM(e.target.value)} />
                            </div>   
                            
                            <div className='col-md-5'>
                                <span className='label'>Dirección: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={DireccionM} onChange={(e) => setDireccionM(e.target.value)} />
                            </div>     

                            <div className='col-md-6'>
                                <span className='label'>E-Mail: </span> {!emailValido && MensajeError('E-mail no válido') }
                                <input type="email" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold', border: emailValido ? '1px solid #ced4da' : '1px solid #dc3545' }} className="form-control form-control-sm" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-2'>
                                <span className='label'>Sisben: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={SisbenM} onChange={(e) => setSisbenM(e.target.value)} />
                            </div>  
                            <div className='col-md-3'>
                                <span className='label'>Entidad de Salud: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={EntidadSM} onChange={(e) => setEntidadSM(e.target.value)} />
                            </div>  
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <span className='label'>Nombres Apellidos Padre: </span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text" style={{width:'96%'}} className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresPadre} onChange={(e) => setNombresPadre(e.target.value)} />
                                <input className="form-check-input ml-2" type="radio" id="rdPadre" value="Padre" disabled={NombresPadre === ''} onClick={() => onChangeIsAcudiente("Padre")} checked={IsPadre} />
                                </div>
                            </div>  
                            <div className='col-md-2'>
                                <span className='label'>Indentifación: </span>
                                <input type="number"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionPadreM} onChange={(e) => setIdentificacionPadreM(e.target.value)} />
                            </div>  

                            <div className='col-md-2'>
                                <span className='label'>Celular: </span>
                                <i className="bi bi-telephone-inbound"></i> <input type="number" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoPadreM} onChange={(e) => setTelefonoPadreM(e.target.value)} />
                            </div>   
                            
                            <div className='col-md-3'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionPadreM} onChange={(e) => setOcupacionPadreM(e.target.value)} />
                            </div>  
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <span className='label'>Nombres Apellidos Madre: </span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input type="text" style={{width:'96%'}} className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresMadre} onChange={(e) => setNombresMadre(e.target.value)} />
                                    <input  className="form-check-input ml-2" type="radio" id="rdMadre" value="Madre" disabled={NombresMadre === ''}  onClick={() => onChangeIsAcudiente("Madre")} checked={IsMadre} />
                                </div>
                            </div>  
                            
                            <div className='col-md-2' >
                                <span className='label'>Indentifación: </span>
                                <input type="number"   className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionMadreM} onChange={(e) => setIdentificacionMadreM(e.target.value)} />
                            </div>  
                            <div className='col-md-2'>
                                <span className='label'>Celular: </span>
                                <i className="bi bi-telephone-inbound"></i> <input type="number" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoMadreM} onChange={(e) => setTelefonoMadreM(e.target.value)} />
                            </div>   

                            <div className='col-md-3'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionMadreM} onChange={(e) => setOcupacionMadreM(e.target.value)} />
                            </div>  
                            
                           
                        </div>
                        
                            <div className='row mt-2'>
                                <div className='col-md-5'>
                                    <span className='label'>Nombres Apellidos Acudiente {ParentescoAc !== "" && <span className='texto_fuxia' >( {ParentescoAc} )</span> }
                                                <>
                                                <a className="icon ml-2" href="#" data-bs-toggle="dropdown" title='Seleccionar Parentesco' aria-expanded="false">
                                                    <i class="bi bi-folder-symlink"></i></a>
                                                    <ul className="dropdown-menu dropdown-menu-end  dropdown-menu-up " >
                                                        {ListadoParentescos.map((parent) => {
                                                            return (
                                                                <li key={parent.id_parentesco}><a className="dropdown-item" href="#" onClick={()=> SeleccionarParentesco(parent.descrip_parentesco)}>
                                                                    <i class="bi bi-list me-1 text-primary"></i>{parent.descrip_parentesco}</a></li>
                                                            )
                                                        })}
                                                    </ul>
                                                    </>
                                                 </span>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="text" style={{width:'96%'}} className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresAcudiente} disabled={!ParentescoAc} onChange={(e) => setNombreAcudiente(e.target.value)} />
                                        <input  className="form-check-input ml-2" type="radio" id="rdAcudiente" value="Otro" disabled={NombresAcudiente === ''}  onClick={() => onChangeIsAcudiente("Otro")} checked={IsAcudiente} />
                                    </div>
                                </div>  
                                <div className='col-md-2'>
                                    <span className='label'>Indentifación: </span>
                                    <input type="number"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionAcudM}  disabled={!ParentescoAc} onChange={(e) => setIdentificacionAcudM(e.target.value)} />
                                </div>  

                                <div className='col-md-2'>
                                    <span className='label'>Celular: </span>
                                    <i className="bi bi-telephone-inbound" ></i> <input type="number" className="form-control form-control-sm estilocampo" id="nombres" name="nombres"  disabled={!ParentescoAc} value={TelefonoAcudM} onChange={(e) => setTelefonoAcudM(e.target.value)} />
                                </div>   
                                
                                <div className='col-md-3'>
                                    <span className='label'>Ocupación: </span>
                                    <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionAcuM}  disabled={!ParentescoAc} onChange={(e) => setOcupacionAcudM(e.target.value)} />
                                </div>  
                            </div>
                       
                                            
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                      <button type="button" className="btn btn-primary btn-sm" disabled={ModIdMatricula===0} onClick={Constancia} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Imprimir Constancia"><i className="bi bi-clipboard-plus"></i> Constancia</button>                      
                      <button type="button" className="btn btn-primary btn-sm" disabled={ModIdMatricula===0} onClick={Imprimir} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Imprimir Matricula"><i className="bi bi-clipboard-plus"></i> Imprimir</button>                      
                      <button type="button" className="btn btn-primary btn-sm" onClick={Guardar} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Guardar Matricula"><i className="bi bi-clipboard-plus"></i> Guardar</button>                      
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="modalTraslado" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> {TIdMatricula === 0 ? <>Nueva Matricula</> : <>Traladar alumno Matricula N° {TIdMatricula}</>}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                              <div className='row'>
                                  <div className='col-md-12 '>
                                    <div className='alert alert-warning alert-dismissible fade show'>
                                    <i class="bi bi-exclamation-triangle me-1"></i> Esta ventana te permite trasladar a un alumno a otra sede de nuestra institución. Solo necesitas seleccionar la sede destino y hacer clic en "Trasladar". Es importante tener en cuenta que esta acción no se puede revertir una vez completada.
                                    </div>
                                  </div>                      
                              </div>                          
                              <div className='row mt-3'>
                                    <div className='col-md-4'> <span className='label'>Alumno</span></div> 
                                    <div className='col-md-8'>
                                        <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombreAlumno}  /> 
                                    </div> 
                                    <div className='col-md-4 label'><span className='label'>Sede destino</span></div> 
                                    <div className='col-md-8 mt-2'>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={SedeTraslado} onChange={(e) => setSedeTraslado(e.target.value)} >
                                            <option key={0} value={null} >Seleccione</option>
                                        {opcionesSedes.map((sede, index) => (
                                            <option key={index} value={sede.value}>{sede.label}</option>
                                        ))}
                                       </select>
                                    </div>
                              </div>                                

                    </div>
                    <div className="modal-footer">
                            <button type="button" disabled={!SedeTraslado} className="btn btn-primary btn-sm" onClick={ProcesarTraslado} data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Procesar traslado"><i className="bi bi-clipboard-plus"></i> Trasladar</button>                                              
                    </div>
                  </div>
                </div>
                </div>    
    </>
  )
}

export default Matriculas