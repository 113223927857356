import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

function CargaAcademica() {

    const [IdAsignacion, setIdAsignacion] = useState(0) 
    const [ListaTiposUS, setListaTiposUS] = useState([]) 
    const [ListaGados, setListaGados] = useState([]) 
    const [ListaGrupos, setListaGrupos] = useState([]) 
    const [ListaDocentes, setListaDocentes] = useState([]) 
    const [ListaAsignatura, setListaAsignatura] = useState([]) 
    const [ListaCarga, setListaCarga] = useState([]) 
    const [Loading, setLoading] = useState(false)
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)
    const [NombreGrado, setNombreGrado] = useState("")
    const [Docente, setDocente] = useState("")
    const [Asignatura, setAsginatura] = useState("")
    const [IH, setIH] = useState("")
 
    useEffect(() => {
        GetData('grados/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGados(result.grados)
            }
        })
        GetData('grupos/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaGrupos(result.grupos)
            }
        })

        GetData('docentes/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaDocentes(result.docentes)
            }
        })

        GetData('asignaturas/todos', null).then ((result) =>{
            let responseJSON = result;
    
            if (responseJSON.error === false){
                setListaAsignatura(result.asignaturas)
            }
        })
 
    }, []) 

    useEffect(() => {
        setListaCarga([])
        Nuevo()
        const gradoEncontrado = ListaGados.find((grado) => grado.codigo === Grado);
        if (gradoEncontrado)
            setNombreGrado(gradoEncontrado.grado)
        else
            setNombreGrado("")

    }, [Grado])
    
    
    const ListarCarga = () => {
        
        setListaCarga([])
        if (Grado){
            setLoading(true)
            GetData('asignaciones/listarcargagrupo/' + Grado + '/' + Grupo, null).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaCarga(result.asignaciones)
                    setLoading(false)
                    return true;
                }
            })
        }
    }

    const Guardar = () => {

        // Verifica si Grado, Docente, Asignatura e IH son null o vacíos
        if (Grado === null || Docente === null || Asignatura === null || IH === "") {
            Swal.fire({
                title: 'Error al Guardar',
                text: "Debe llenar todos los parámetros para poder guardar",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return; // Detiene la ejecución si falta algún parámetro
        }

        // Verifica si el valor de IH no es un número
        if (isNaN(IH)) {
            Swal.fire({
                title: 'Error al Guardar',
                text: "El valor de IH debe ser un número",
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return; // Detiene la ejecución si IH no es un número
        }

        const datos = {
            id_carga_academica: IdAsignacion ? IdAsignacion : 0,
            cod_grado: Grado,
            id_grupo: Grupo,
            id_docente: Docente,
            id_asignatura: Asignatura,
            intensidad_horaria: IH,          
        }
          
        PostData('asignaciones/save', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Cargar Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  ListarCarga();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const Editar = (dato) => {
          setIdAsignacion(dato.id_carga_academica)
          setDocente(dato.id_docentes)
          setAsginatura(dato.id_asignatura)
          setIH(dato.Intensidad_horaria)  
    }

    const Eliminar = (dato) => {

          Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo'
        }).then((result) => {
            if (result.isConfirmed) {
                // Realiza la acción de eliminación si el usuario confirma
                const id_carga = dato.id_carga_academica;
                const cod_grado = dato.cod_grado;
                const id_grupo = dato.id_grupo;
                const id_asignatura = dato.id_asignatura;

                GetData('asignaciones/delete/' + id_carga + '/' + cod_grado + '/' + id_grupo + '/' + id_asignatura, null).then ((result) =>{

                    let responseJSON = result;
        
                    if (responseJSON.error === false){
                        Swal.fire({
                            title: 'Eliminado',
                            text: 'El elemento ha sido eliminado correctamente',
                            icon: 'success'
                        });
                        ListarCarga();
                    }
                })
                // Muestra un mensaje de éxito después de eliminar
              
            }
        });
    }

    const Nuevo = () => {
        
        setIdAsignacion(0)
        setDocente("")
        setAsginatura("")
        setIH("")  
    }

  return (
    <>
      <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Desempeños</div>    
            <div className="card-body pb-0">
                
                <div className="formulario">
                    <form method="post" >

                            <div className='row mt-2'>

                                    <div className='col-md-2'>
                                        <strong>Grado: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grado} onChange={(e) => setGrado(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGados.map(tipo => {
                                                    return <option key={tipo.id_grados} value={tipo.id_grados}>{tipo.nombre_grado}</option>
                                            })}
                                        </select> 
                                      
                                    </div>    
                                    <div className='col-md-2'>
                                        <strong>Grupo: </strong>
                                        <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Grupo} onChange={(e) => setGrupo(e.target.value)} >
                                        <option defaultValue={null}>Seleccionar</option>
                                        {ListaGrupos.map(tipo => {
                                                    return <option key={tipo.id_grupo} value={tipo.id_grupo}>{tipo.num_grupo}</option>
                                            })}
                                        </select> 
                                      
                                    </div>    
                                    <div className='col-md-2'>
                                        <button type="button" className="btn btn-info btn-sm mt-4" onClick={ListarCarga} disabled={Grado===null}>
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </div>
                                            
                               {/*  <div className='col-md-3 filter d-flex justify-content-end'>
                                    
                                </div> */}
                            </div>
                            <hr />
                        <div className='row mt-1'>
                            
                            <div className='col-md-12'>
                                   {IdAsignacion > 0 ? <span className="badge bg-warning text-dark">Editando {IdAsignacion}</span>  : <span className="badge bg-info text-dark">Nuevo</span>}
                            </div>
                            <div className='col-md-3'>
                            <strong>Docente: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Docente} onChange={(e) => setDocente(e.target.value)} >
                                <option defaultValue="">Seleccionar</option>
                                {ListaDocentes.map(tipo => {
                                            return <option key={tipo.id_docentes} value={tipo.id_docentes}>{tipo.nombres_apellidos_doc}</option>
                                    })}
                                </select> 
                            </div>    
                            <div className='col-md-3'>
                                <strong>Asignaturas: </strong>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Asignatura} onChange={(e) => setAsginatura(e.target.value)}  >
                                <option value="">Seleccionar</option>
                                {ListaAsignatura.map(tipo => {
                                            return <option key={tipo.id_asignatura} value={tipo.id_asignatura}>{tipo.nombre_asignatura}</option>
                                    })}
                                </select> 
                            </div>    
                            <div className='col-md-1'>
                                <strong>I.H: </strong>
                                <input type="number" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IH} onChange={(e) => setIH(e.target.value)} />
                            </div>   
                            <div className='col-md-2'>
                                    <button type="button" className="btn btn-primary btn-sm mt-4 " onClick={Nuevo}>
                                            Nuevo
                                    </button>
                                    <button type="button" className="btn btn-info btn-sm mt-4 ml-2" onClick={Guardar} disabled={Grado === null || Docente === null || Asignatura===null || IH === "" }>
                                            Guardar
                                    </button>
                            </div> 
                        </div>
                    </form>
                </div>
                <div className="card-body pb-0">
                    <h5 className="card-title">Carga Acedemica - Grado: {NombreGrado}
                    </h5>

                    <div className='row'>
                       
                    </div>
                    {Loading && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table className="table table-striped">
                        <thead>
                        <tr key={0}>
                            <th scope="col">#</th>
                            <th scope="col">Docente</th>
                            <th scope="col">Asignatura</th>
                            <th scope="col">I.H</th>                            
                            <th scope="col">OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaCarga.map((us, index) => 
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{us.nombres_apellidos_doc}</td>
                                <td>{us.nombre_asignatura}</td>
                                <td>{us.Intensidad_horaria}</td>
                                <td>    
                                    <BotonTabla icono='lapiz.png' titulo='Modificar' evento={()=>Editar(us)} />
                                    <BotonTabla icono='borrar.png' titulo='Borrar' evento={()=>Eliminar(us)} />
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
               </div>
            </div>
        </section>        
    </>
  )
}

export default CargaAcademica