import React, { useEffect, useState } from 'react'
import BotonRound from './BotonRound';
import { PostData } from '../services/PostData';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FilaEditIndicador = ({id_indicador, codigo_indic, id_periodo, descripcion_indic, grado, id_docente, asig, ActulizarTabla, setActulizarTabla}) => {
    
    const [isEditing, setIsEditing] = useState(false);
    const [Codigo, setCodigo] = useState(codigo_indic)
    const [Descripcion, setDescripcion] = useState(descripcion_indic)
    const [Periodo, setPeriodo] = useState(id_periodo)
    const [LoadingTabla, setLoadingTabla] = useState(false)
    
    
/*  useEffect(() => {
    if (isEditing) {
            inputRef.current.focus();
        }
    }, [isEditing]); */

    const handleBlur = () => {
        setIsEditing(false);
    // onSave(value);
    };

    const guardarDatoIndicador = () => {
          
        const datos = {
            id_indicador: id_indicador,
            codigo_indic: Codigo,
            descripcion_indic: Descripcion,
            id_grado: grado,
            id_asigantura: asig,
            id_periodo: Periodo
        }

        if (id_periodo !== Periodo){ //si los periodos son distintos se guarda
            guardarPeriodo()
        }

        setLoadingTabla(true)
        PostData('indicadores/save', datos).then ((result) =>{
            if (result.error === false){   
                toast.success('Datos del Indicador guardado con exito', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });    
                
                setIsEditing(false)
            }else{
                toast.error(result.mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    }); 
            }
            setLoadingTabla(false)

        })

       
    }

    const guardarPeriodo = () => {

        const datos = {
            id_indicador: id_indicador,
            codigo_indic : Codigo,
            descripcion_indic : Descripcion,
            id_grado : grado,
            id_docente: id_docente, 
            id_asigantura: asig, 
            id_periodo : Periodo
        }
        setLoadingTabla(true)
        PostData('indicadores/guardarindicadordocenteadmin', datos).then ((result) =>{            
            if (result.error === false){   
                toast.success(result.mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });   
                    setActulizarTabla(!ActulizarTabla)     
            }else{
                toast.error(result.mensaje, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    }); 
                    setPeriodo(id_periodo)
            }
            setIsEditing(false)
            setLoadingTabla(false)
        })        
    }

    const cancelarAccion = () => {
        setPeriodo(id_periodo)
        setIsEditing(false)
    }
 return (
    <>
    {isEditing ? (
    <tr>
          <td align='center'>            
                <input type="text"  
                className="form-control form-control-sm estilocampo" id="nombres" name="nombres" 
                style={{textAlign:'center'}}
                value={Codigo}  onChange={(e) => setCodigo(e.target.value)} disabled />
          </td>
          <td> <textarea  
                    className="form-control form-control-sm estilocampo" 
                    value={Descripcion}
                    rows={4}
                    onChange={(e) => setDescripcion(e.target.value)}
                    onBlur={handleBlur}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleBlur();
                        }
                    }}
                    disabled
                    >
                        {Descripcion}
                        
                </textarea>           
                {LoadingTabla && 
                    <div className="progress mt-1 mb-2">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }   
          </td>
          <td align='center'>
                                <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)} >
                                        <option value={0}> - </option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                </select>
            </td>
          <td>
          {isEditing && id_periodo !== Periodo && <>
            <BotonRound icono='ri-save-3-line' titulo='Guardar' evento={()=> guardarPeriodo()}  />
            <BotonRound icono='ri-arrow-go-back-fill' titulo='Cancelar' evento={()=> cancelarAccion()}  />
            </>
         }

          </td>
    </tr>
    ):(
    <tr>
         <td align='center'>{Codigo}</td>
         <td>{Descripcion}</td>
         <td align='center'>{Periodo}</td>
         <td>
            {!isEditing && <button className='btnround ml-2' onClick={()=> setIsEditing(true)}><i class="bi bi-pencil"></i></button>    }
            
         </td>
    </tr>
    )}
        

    </>
  )
  
}

export default FilaEditIndicador