import React, { useEffect, useState } from 'react'
import { validarEmail, calculateEdad } from '../components/utils';
import { useParams } from 'react-router-dom';
import { GetData } from '../services/GetData';
import config from '../config';
import { getSede, getToken } from '../services/Usuario';
import FileViewerDialog from '../components/FileViewerDialog';


const VerHojaMatricula = () => {
     const { idmatricula } = useParams();
     /* datos select */   
     const [UrlFile, setUrlFile] = useState("")
     const [openImpresion, setopenImpresion] = useState(false)
     const [ListaGados, setListaGados] = useState([])
     const [ListaGrupos, setListaGrupos] = useState([])
     const [ListaEstados, setListaEstados] = useState([])
     const [TiposIdentificacion, setTiposIdentificacion] = useState([])
     const [ListaMunicipios, setListaMunicipios] = useState([])
     const [ListaTSanguineo, setListaTSanguineo] = useState([])
     const [ListaJornadas, setListaJornadas] = useState([])

    /* datos dela matricula */
    const [IdMatricula, setIdMatricula] = useState(0)
    const [NumMatricula, setNumMatricula] = useState(0)
    const [IdAlumno, setIdAlumno] = useState(0)
    const [NombreAlumno, setNombreAlumno] = useState('')
    const [Identificacion, setIdentificacion] = useState('')
    const [IdGrado, setIdGrado] = useState(null)
    const [IdGrupo, setIdGrupo] = useState(null)
    const [GradoM, setGradoM] = useState(null)
    const [GrupoM, setGrupoM] = useState(null)    
    const [JornadaM, setJornadaM] = useState(null)    
    const [EstadoM, setEstadoM] = useState(null)    
    const [FechaNacM, setFechaNacM] = useState(null)    
    const [Edad, setEdad] = useState(0)    
    const [TelefonoM, setTelefonoM] = useState(null)    
    const [DireccionM, setDireccionM] = useState(null)    
    const [TipoIdent, setTipoIdent] = useState(null)    
    const [Municipio, setMunicipio] = useState(null)    
    const [TipoSangre, setTipoSangre] = useState(null)    
    const [SexoM, setSexoM] = useState(null)    
    const [EmailM, setEmailM] = useState(null)    
    const [emailValido, setEmailValido] = useState(true);  

    const [IdPadre, setIdPadre] = useState(0);
    const [NombresPadre, setNombresPadre] = useState('');
    const [TelefonoPadreM, setTelefonoPadreM] = useState('');
    const [IdentificacionPadreM, setIdentificacionPadreM] = useState('');
    const [OcupacionPadreM, setOcupacionPadreM] = useState('');
    const [IdMadre, setIdMadre] = useState(0);
    const [NombresMadre, setNombresMadre] = useState('');
    const [TelefonoMadreM, setTelefonoMadreM] = useState('');
    const [IdentificacionMadreM, setIdentificacionMadreM] = useState('');
    const [OcupacionMadreM, setOcupacionMadreM] = useState('');
    const [IdAcudiente, setIdAcudiente] = useState(0);
    const [NombresAcudiente, setNombreAcudiente] = useState('');
    const [TelefonoAcudM, setTelefonoAcudM] = useState('');
    const [IdentificacionAcudM, setIdentificacionAcudM] = useState('');
    const [OcupacionAcuM, setOcupacionAcudM] = useState('');
    const [ParentescoAc, setParentescoAc] = useState('');
    const [SisbenM, setSisbenM] = useState('');
    const [EntidadSM, setEntidadSM] = useState('');
    const [InstitucionAnt, setInstitucionAnt] = useState('');
    const [ListadoParentescos, setListadoParentescos] = useState([]);
    
    const [IsPadre, setIsPadre] = useState(true);
    const [IsMadre, setIsMadre] = useState(false);
    const [IsAcudiente, setIsAcudiente] = useState(false);
    const [NotasEstudiante, setNotasEstudiante] = useState([]);
    const [ListaActividades, setListaActividades] = useState([]);
    const [NumPerdidas, setNumPerdidas] = useState(0);
    const [AsignaturaSel, setAsignaturaSel] = useState('');

    const [Loading, setLoading] = useState(false);  

    useEffect(() => {
      
        ///

        setLoading(true)
        GetData('matriculas/datosmatricula/' + idmatricula, null).then ((result) =>{
            let responseJSON = result;
            
            if (responseJSON.error === false){                
                const datosm = result.matricula;
                
                setIdGrado(datosm.cod_grado)
                setIdGrupo(datosm.id_grupo)
                setIdMatricula(datosm.id_matricula)
                setIdAlumno(datosm.id_alumnos)
                setNombreAlumno(datosm.nombres_apellidos_al)
                setIdentificacion(datosm.num_documento_al)
                setGradoM(datosm.nombre_grado)
                setGrupoM(datosm.id_grupo)
                setJornadaM(datosm.jornada)
                setEstadoM(datosm.estado) 
                setFechaNacM(datosm.fecha_nacimiento_al)
                setTelefonoM(datosm.telefono_al)
                setDireccionM(datosm.direccion_al)
                setTipoIdent(datosm.abreviatura_doc)
                setMunicipio(datosm.municipio)
                setTipoSangre(datosm.tipo_sangre)
                setSexoM(datosm.sexo)
                setEmailM(datosm.email_al)
                setEdad(calculateEdad(datosm.fecha_nacimiento_al))
                setEntidadSM(datosm.seguro_alumno)
                setSisbenM(datosm.sisben)
                setInstitucionAnt(datosm.institucion_anterior)


                setIsPadre(false)
                setIsMadre(false)
                setIsAcudiente(false)

                setNombresPadre("")
                setTelefonoPadreM("")
                setIdentificacionPadreM("")
                setOcupacionPadreM("")

                setNombresMadre("")
                setTelefonoMadreM("")
                setIdentificacionMadreM("")
                setOcupacionMadreM("")

                setIdAcudiente(0)
                setNombreAcudiente("")
                setTelefonoAcudM("")
                setIdentificacionAcudM("")
                setOcupacionAcudM("")
                setParentescoAc("")


                const datospadres = result.padres;

                datospadres.forEach(datop => {
                        if (datop.descrip_parentesco === "Padre"){                            
                            setIdPadre(datop.id_acudientes_alumno)
                            setNombresPadre(datop.nombre_apellidos_ac)
                            setTelefonoPadreM(datop.telefonos_ac)
                            setIdentificacionPadreM(datop.identificacion_acud)
                            setOcupacionPadreM(datop.ocupacion_ac)
                            if (datop.acudiente_ac === 'S')
                                setIsPadre(true)
                        }else{
                            if (datop.descrip_parentesco === "Madre"){
                                setIdMadre(datop.id_acudientes_alumno)
                                setNombresMadre(datop.nombre_apellidos_ac)
                                setTelefonoMadreM(datop.telefonos_ac)
                                setIdentificacionMadreM(datop.identificacion_acud)
                                setOcupacionMadreM(datop.ocupacion_ac)
                                if (datop.acudiente_ac === 'S')
                                    setIsMadre(true)
                            }else{
                                setIdAcudiente(datop.id_acudientes_alumno)
                                setNombreAcudiente(datop.nombre_apellidos_ac)
                                setTelefonoAcudM(datop.telefonos_ac)
                                setIdentificacionAcudM(datop.identificacion_acud)
                                setOcupacionAcudM(datop.ocupacion_ac)
                                setParentescoAc(datop.descrip_parentesco)
                                if (datop.acudiente_ac === 'S')
                                    setIsAcudiente(true)
                            }
                        }
                });
                
            }
            setLoading(false)
        });

     
    }, [idmatricula])

    useEffect(() => {
        
        if (IdGrado !== null && IdGrupo !== null){
            setNotasEstudiante([])
            GetData('estadisticas/historinotasalumno/'+idmatricula+'/'+IdGrado+'/'+IdGrupo).then ((result) =>{
                let responseJSON = result;
                
                if (responseJSON.error === false){        
                    setNotasEstudiante(result.notas)
                    setNumPerdidas(result.numperd)
                }
            })
        }

    }, [IdGrado,IdGrupo, idmatricula])
    

    const VerActvidades = (id_asignatura, asignatura) => {
        setAsignaturaSel(asignatura)
        GetData('calificaciones/notasindicadoralumno/'+idmatricula+'/'+ IdGrado +'/'+id_asignatura).then ((result) =>{
            let responseJSON = result;
            
            if (responseJSON.error === false){        
                setListaActividades(result.notas)
                const modal = document.getElementById('modalActividades');

                // Verificar si el modal existe antes de intentar abrirlo
                if (modal) {
                // Usar el método modal() para abrir el modal
                    const modalInstancia = new window.bootstrap.Modal(modal);
                    modalInstancia.show();
                }
            }
        })
    }

    const formatonumero = (numero) => {
        return !isNaN(numero) ? parseFloat(numero).toFixed(1) : numero;
    }
    
    const handleCloseImpresion = () => {
        setopenImpresion(false);
    };
    
    const Constancia = () => {
        const url_informe = config.API_URL_INFORMES;
        const url = url_informe + '/constancia.php?id_matricula='+idmatricula+'&token='+getToken()+'&db='+getSede()
        //window.open(url, "_blank");   
        setUrlFile(url)
        setopenImpresion(true)
    }

  return (
    <>
            <section className='section dashboard container'>
            <div className="card">
                
                <div className="card-body">
                    <h4 className="card-title">Hoja de Matricula N° {idmatricula} - {NombreAlumno} </h4>
                    <hr className='bordetitulo' />
                {Loading && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                        <div className='row'>

                            <div className='col-md-2'>
                                        <span className='label'>Grado: </span>
                                        <input type="text"  className="form-control form-control-sm estilocampo" id="grado" name="grado" value={GradoM} />
                                    
                            </div>    
                            <div className='col-md-2'>
                                        <span className='label'>Grupo: </span>
                                        <input type="text"  className="form-control form-control-sm estilocampo" id="grupo" name="grupo" value={GrupoM} />
                                    
                            </div>  
                            <div className='col-md-2'>
                                        <span className='label'>Jornada: </span>
                                        <input type="text"  className="form-control form-control-sm estilocampo" id="jornada" name="jornada" value={JornadaM} />
                            </div>
                            <div className='col-md-2'>
                                        <span className='label'>Estado: </span>
                                        <input type="text"  className="form-control form-control-sm estilocampo" id="estado" name="estado" value={EstadoM} />

                            </div>
                            <div className='col-md-4 text-end'>
                                <button type="button" className="btn btn-primary btn-sm mt-4 ml-2" onClick={Constancia}  >
                                    <i className="bi bi-file-earmark-pdf"></i> Constancia
                                </button>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <div className="input-group">
                                <span className='label'>Nombre Alumno: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" style={{width: '90%'}} id="nombres" name="nombres" value={NombreAlumno} onChange={(e) => setNombreAlumno(e.target.value)} />
                                <button type="button" className="btn btn-info btn-sm" title='Seleccionar Alumno' onClick="" >
                                        <i className="bi bi-search"></i>
                                </button>
                                </div>
                            </div>    
                            <div className='col-md-2'>
                                <span className='label'>Tipo Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="jornada" name="jornada" value={TipoIdent} />
                            </div>  
                            <div className='col-md-4'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>     
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-5'>
                                <span className='label'>Lugar de Expedición: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="jornada" name="jornada" value={Municipio} />
                            </div> 
                            <div className='col-md-3'>
                                <span className='label'>Fecha de Nacimiento: </span>
                                <input type="date" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={FechaNacM} onChange={(e) => setFechaNacM(e.target.value)} />
                            </div>     
                            <div className='col-md-1'>
                                <span className='label'>Edad: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="edad" name="edad" value={Edad} disabled  />
                            </div>
                            <div className='col-md-3'>
                                <span className='label'>Tipo de Sangre: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="tipos" name="tipos" value={TipoSangre}  />
                            </div> 
                          
                        </div>
                        <div className='row mt-2'>
                        <div className='col-md-3'>
                                <span className='label'>Sexo: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="sexo" name="sexo" value={SexoM}  />
                            </div>
                             <div className='col-md-3'>
                                <span className='label'>Teléfono / Célular: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoM} onChange={(e) => setTelefonoM(e.target.value)} />
                            </div>   
                            
                            <div className='col-md-5'>
                                <span className='label'>Dirección: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={DireccionM} onChange={(e) => setDireccionM(e.target.value)} />
                            </div>     

                            <div className='col-md-6'>
                                <span className='label'>Correo electrónico: </span>
                                <input type="email" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold', border: emailValido ? '1px solid #ced4da' : '1px solid #dc3545' }} className="form-control form-control-sm" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-3'>
                                <span className='label'>Entidad de Salud: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-3'>
                                <span className='label'>Sisbén: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={SisbenM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                            <div className='col-md-6'>
                                <span className='label'>Institución donde curso año anterior: </span>
                                <input type="text" className="form-control form-control-sm estilocampo" id="email" name="email" value={InstitucionAnt} onChange={(e) => setEmailM(e.target.value)} />
                            </div>  
                        </div>

                        <h4 className="card-title">Datos de Padres y Acudiente</h4>
                        <hr className='bordetitulo' />

                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresPadre}  />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos del Padre: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionPadreM} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoPadreM}  />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionPadreM}  />
                            </div>               
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked={IsPadre} />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>               
                        </div>
                        <hr className='bordetitulo mt-2' />
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionMadreM} />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos de la Madre: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresMadre} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoMadreM}  />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionMadreM}  />
                            </div>               
                           
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked={IsMadre} />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>        
                        </div>
                        <hr className='bordetitulo mt-2' />
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>No. Identificación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={IdentificacionAcudM}  />
                            </div>               
                            <div className='col-md-6'>
                                <span className='label'>Nombres y Apellidos del Acudiente: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={NombresAcudiente} />
                            </div>               
                            <div className='col-md-3'>
                                <span className='label'>Célular: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={TelefonoAcudM} />
                            </div>               
                            <div className='col-md-2'>
                                <span className='label'>Ocupación: </span>
                                <input type="text"  className="form-control form-control-sm estilocampo" id="nombres" name="nombres" value={OcupacionAcuM} />
                            </div>               
                           
                            <div className='col-md-2'>
                            <div className="form-check mt-4">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked={IsAcudiente} />
                                <label className="form-check-label" for="gridRadios1">
                                    Es Acudiente
                                </label>
                                </div>
                            </div>  
                        </div>

                        

                </div> {/* fin card-body */}
            </div>

            <div className="card">
                
                <div className="card-body cnotas">
                    <h4 className="card-title">Calificaciones </h4>

                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Docente</th>
                            <th scope="col">Asignatura</th>
                            <th scope="col">Periodo 1</th>
                            <th scope="col">Periodo 2</th>
                            <th scope="col">Periodo 3</th>
                            <th scope="col">Periodo 4</th>
                            <th scope="col">Prom</th>
                            <th scope="col">Sum</th>
                            <th scope="col">Rest</th>
                        </tr>
                        </thead>
                        <tbody>
                            {NotasEstudiante.map((us, index) => (
                                <tr key={us.id_asignatura}>
                                <td>{us.docente}</td>
                                <td><a href='#' data='Ver Actividades' onClick={()=>VerActvidades(us.id_asignatura, us.asignatura)}>{us.asignatura}</a></td>
                                {us.notas.map((notaPeriodo, indexPeriodo) => (
                                        <td key={indexPeriodo}>
                                        {notaPeriodo.length > 0 && notaPeriodo[0].nota_fn < 3 ? (
                                            <span className='nota_perdida'>{formatonumero(notaPeriodo[0].nota_fn)}</span>
                                        ) : (
                                            notaPeriodo.length > 0 ? formatonumero(notaPeriodo[0].nota_fn) : ''
                                        )}
                                        </td>
                                    ))}
                                <td>
                                  {us.promedio < 3 ?  <span className='nota_perdida'>{parseFloat(us.promedio).toFixed(1)}</span> 
                                    :
                                    <span>{parseFloat(us.promedio).toFixed(1)}</span>  
                                }
                                    
                                </td>
                                <td>{us.suma}</td>
                                    <td>{us.resta}</td>

                                </tr>
                            ))}
                            </tbody>
                    </table>                    
                    <div className='row'>
                        <div className='col-md-3'>
                            <span className='label'>Num. Perdidas: </span> {NumPerdidas}
                        </div>
                    </div>
                    </div>

                </div>
            </section>


            <div className="modal fade" id="modalActividades" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-bar-chart-line"></i> Actividades Asignatura:  {AsignaturaSel} </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body " >
                        <div className='row ml-2'>
                            <div className='col-md-12'>
                            <strong className='ml-3'>N° Indicadores: </strong><span className='nomagrado'>{ListaActividades.length}</span>
                           </div>
                        </div>
                        <div className='row mt-3 '>

                        <table className="table table-striped table-hover"  >
                        <thead>
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Descripción Indicacdor</th>
                            <th scope="col">Periodo</th>
                            <th scope="col">Nota</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaActividades.map((act, index) => 
                            <tr key={act.id_nota_indicador}>
                                <td>{act.fecha}</td>
                                <td>{act.indicador}</td>
                                <td>{act.id_periodo}</td>                                                     
                                <td key={index} style={{ textAlign: 'center' }}>
                                     {parseFloat(act.nota_indic) < 3 ? <span className='nota_perdida'>{parseFloat(act.nota_indic).toFixed(1)}</span> : parseFloat(act.nota_indic).toFixed(1)}
                                    </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>          

                        </div>
                    </div>
                </div>
        </div>    
    </div>


    <FileViewerDialog open={openImpresion} handleClose={handleCloseImpresion} fileUrl={UrlFile} />
    </>    
    
  )
}

export default VerHojaMatricula