import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BotonTabla from '../components/BotonTabla';

const Docentes = () => {

    const [ListaDocentes, setListaDocentes] = useState([])
    const [ListaGados, setListaGados] = useState([])
    const [ListaGrupos, setListaGrupos] = useState([])
    const [ListaEstados, setListaEstados] = useState([])
    const [NombreGrado, setNombreGrado] = useState("")
    const [Grado, setGrado] = useState(null)
    const [Grupo, setGrupo] = useState(null)    
    const [Activo, setActivo] = useState(true);

    const [Loading, setLoading] = useState(false)    
    const [LoadingTabla, setLoadingTabla] = useState(false)    
    const [LoadingForm, setLoadingForm] = useState(false)    

    const [NombreDocente, setNombreDocente] = useState('')
    const [Nombre, setNombre] = useState('')
    const [Estado, setEstado] = useState(1)

    const [Correo, setCorreo] = useState("") 
    const [Password, setPassword] = useState("")

    const [ModNMatricula, setModNMatricula] = useState(0)
    const [ModIdDocente, setModIdDocente] = useState(0)
    const [Identificacion, setIdentificacion] = useState('')
    const [EstadoM, setEstadoM] = useState(0)    
    const [FechaNacM, setFechaNacM] = useState(null)    
    const [TelefonoM, setTelefonoM] = useState(null)    
    const [DireccionM, setDireccionM] = useState(null)    
    const [SexoM, setSexoM] = useState('m')    
    const [EdadM, setEdad] = useState('0')    
    const [EmailM, setEmailM] = useState('')    
    const [TituloM, setTituloM] = useState('')    
    const [EscalafonM, setEscalafonM] = useState('')    
    const [listaDocentesFiltrada, setListaDocentesFiltrada] = useState([]);
    const [emailValido, setEmailValido] = useState(true);
    
    useEffect(() => {
        ConsultarDocentes()
    
    }, []) 

    useEffect(() => {
        const filteredDocentes = ListaDocentes.filter(docente =>
            docente.nombres_apellidos_doc.toLowerCase().includes(Nombre.toLowerCase())
        );
        setListaDocentesFiltrada(filteredDocentes);
    }, [Nombre])
    
    useEffect(() => {
        setEmailValido(validarEmail(EmailM));
    }, [EmailM])

    useEffect(() => {
        
        ConsultarDocentes()
    }, [Activo])
    

    const ConsultarDocentes = () => {
            setLoading(true)
            const estado = Activo ? 1 : 0;
            console.log(Estado, estado)

            GetData('docentes/'+estado, null).then ((result) =>{
                let responseJSON = result;
        
                if (responseJSON.error === false){
                    setListaDocentes(result.docentes)
                    setListaDocentesFiltrada(responseJSON.docentes);
                    setLoading(false)
                    return true;
                }
            })

    } 

    const Nuevo = () => {
        const modal = document.getElementById('modalD');

        setModIdDocente(0)
        setNombreDocente('')
        setIdentificacion('')
        setFechaNacM('')
        setTelefonoM('')
        setDireccionM('')
        setEmailM('')
        setSexoM('m')
        setEdad(0)
        setTituloM('')
        setEscalafonM('')

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const calculateAge = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
    
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    };
    
    const validarEmail = (email) => {
        // Expresión regular para validar un correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleFechaNacChange = (e) => {
        const nuevaFecha = e.target.value;
        setFechaNacM(nuevaFecha);
        const edadCalculada = calculateAge(nuevaFecha);
        setEdad(edadCalculada);
    };
  
    const Modificar = (dato) => {
        
        setLoadingForm(true)
        GetData('docentes/datos/' + dato.id_docentes, null).then ((result) =>{
            let responseJSON = result;
            
            if (responseJSON.error === false){                
                const datosm = result.datos;
                
                const sexo = datosm.sexo ? datosm.sexo : 'M'

                setModIdDocente(datosm.id_docentes)
                setNombreDocente(datosm.nombres_apellidos_doc)
                setIdentificacion(datosm.identificacion_doc)
                setFechaNacM(datosm.fecha_nacimiento_doc)
                setTelefonoM(datosm.telefonos_doc)
                setDireccionM(datosm.direccion_doc)
                setEmailM(datosm.email_doc)
                setSexoM(sexo)
                setEdad(calculateAge(datosm.fecha_nacimiento_doc))
                setTituloM(datosm.titulo_doc)
                setEscalafonM(datosm.escalafon)
                setEstadoM(datosm.estado_doc)
                setLoadingForm(false)
            }
        })

        

         

        const modal = document.getElementById('modalD');

        // Verificar si el modal existe antes de intentar abrirlo
        if (modal) {
          // Usar el método modal() para abrir el modal
          const modalInstancia = new window.bootstrap.Modal(modal);
          modalInstancia.show();
        }
    }

    const Eliminar = (id_matricula, nombre) => {

        Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! ¿Estas seguro de eliminar a: ' + nombre + '?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
             

              GetData('asignaciones/delete/' + id_matricula, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El elemento ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      ConsultarDocentes();
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
      
  }

  const Guardar = () => {
            // Verifica si Grado, Docente, Asignatura e IH son null o vacíos
            if (NombreDocente === "" || Identificacion === "" || TelefonoM === "" || DireccionM === "" || EmailM === "" || FechaNacM === "" || TituloM === "" || EscalafonM === "" ) {
                Swal.fire({
                    title: 'Error al Guardar',
                    text: "Debe llenar todos los campos para poder guardar",
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                return; // Detiene la ejecución si falta algún parámetro
            }

            const datos = {
                id_docentes : ModIdDocente, 
                nombre: NombreDocente, 
                identificacion: Identificacion,
                celular: TelefonoM,
                direccion: DireccionM, 
                email: EmailM, 
                fecha_nac: FechaNacM,
                titulo: TituloM, 
                escalafon: EscalafonM,
                sexo: SexoM,
                estado: EstadoM
            }
            PostData('docentes/save', datos).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){
                    Swal.fire({
                        title: 'Cargar Guardado',
                        text: 'Guardado con exito.',
                        icon:"success",
                        confirmButtonText: 'Aceptar'
                      });
                      ConsultarDocentes();
                 }else{
                  Swal.fire({
                    title: 'Error al Guardar',
                    text: result.mensaje,
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                  });
                  
              }
            })
  }

  const crearUsuario = (id_docente) => {
    

    setCorreo("")
    setPassword("")


    const modal = document.getElementById('modalUsuario');

    // Verificar si el modal existe antes de intentar abrirlo
    
    const datos = {
        id_docentes: id_docente,
        id_usuarios: 0
    }

    setLoading(true)
    PostData('docentes/crearusuario/', datos).then ((result) =>{
        let responseJSON = result;
        if (responseJSON.error === false){            
            setCorreo(responseJSON.email)
            setPassword(responseJSON.password)
            setLoading(false)      
            
            if (modal) {
                // Usar el método modal() para abrir el modal
                const modalInstancia = new window.bootstrap.Modal(modal);
                modalInstancia.show();
            }
            ConsultarDocentes();
            toast.success('Contraseña generada con exito', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });                      
        }
    });

  }

  const ConfirmarCrearUsuario = (id_docente, nombre) => {
    Swal.fire({
        title: 'Crear usuario',
        text: '¿Deseas crear un nuevo usuario para el Docente: '+ nombre+ '?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Crear',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {            
            crearUsuario(id_docente);                    
        }
      });
  }

  const EliminarDocente = (id_docente, nombre) => {
   
    Swal.fire({
        title: "Estas seguro?",
        text: '¿Está seguro de borrar el Docente: ' + nombre + '?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",        
        confirmButtonText: "Si, borralo!",
        cancelButtonText: "No, cancelar!",
    }).then((result) => {
        if (result.isConfirmed) {
            // Llama a la función para eliminar el docente
            const datos = {
                id_docente: id_docente,
                id_usuarios: 0
            }
            setLoading(true)
            PostData('docentes/delete/', datos).then ((result) =>{
                let responseJSON = result;
                if (responseJSON.error === false){      
                    ConsultarDocentes();   
                    toast.success('Docente borrado con exito', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }   
            })  
            
        }
    });
}


  return (    
        <>
       <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Docentes</div>    
            <div className="card-body pb-0">
                <div className="formulario">
                    <form method="post" >
                        

                        <div className='row'>
                            <div className='col-md-2'>
                                Filtrar: 
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="fnombres" name="fnombres" value={Nombre} onChange={(e) => setNombre(e.target.value)} />
                            </div>                                                          

                            <div className='col-md-2'>
                                <button type="button" className="btn btn-primary ml-2 btn-sm" style={{marginTop: '25px'}} onClick={Nuevo}  >
                                        <i className="bi bi-person-plus"></i> Nuevo Docente
                                </button>
                            </div>  
                            <div className='col-md-1'>
                            <div className="form-check form-switch" style={{marginTop: '25px'}}>
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={Activo} onChange={(e) => setActivo(!Activo)} />
                                <label className="form-check-label" for="flexSwitchCheckChecked">Activos/Inactivos</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card-body pb-0">
                    <h5 className="card-title">Lista de Docentes  <span className='ml-4'>Total: </span> {ListaDocentes.length}
                    </h5>

                    <div className='row'>
                       
                    </div>
                    {Loading && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Nombre del Docente</th>
                            <th scope="col">Identifiación</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Título</th>
                            <th scope="col">Cuenta</th>
                            <th scope="col" style={{width:'15%'}}>OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {listaDocentesFiltrada.map((us, index) => 
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                    <td> 
                                    <img src='/assets/img/docente.png' width={25} alt='icono' />
                                     {us.nombres_apellidos_doc}
                                       {us.es_director === 'S' && <img src='/assets/img/director_grupo.png' width={25} className='ml-4' title='Es Director de grupo' />}
                                     </td>
                                <td>{us.identificacion_doc}</td>
                                <td>{us.telefonos_doc}</td>
                                <td>{us.email_doc}</td>
                                <td align='center'>
                                   { parseInt(us.id_usuario) > 0 && <img src='/assets/img/bloqueado.png' width={20}  /> }
                                    </td>
                                <td>    

                                    <BotonTabla icono='llave.png' titulo='Crear Usuarios' evento={()=> ConfirmarCrearUsuario(us.id_docentes, us.nombres_apellidos_doc)} />
                                    <BotonTabla icono='lapiz.png' titulo='Modificar Docente' evento={()=>Modificar(us)} />
                                    <BotonTabla icono='borrar.png' titulo='Borrar Docente' evento={()=>EliminarDocente(us.id_docentes , us.nombres_apellidos_doc)} />
                                    
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
            </div>
        </section>      

        <div className="modal fade" id="modalUsuario" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Crear Usuario Docente</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p style={{ marginTop: '1rem' }}>
                            Aquí tienes la información de inicio de sesión del docente.
                            Asegúrate de compartir estos detalles de forma segura y confidencial.
                        </p>
                        <div>
                            <strong>Correo:</strong> {Correo}
                        </div>
                        <div>
                            <strong>Contraseña:</strong> {Password}
                        </div>
                    </div>
                 </div>
            </div>       
            <div className="modal-footer">
                   <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
            </div>
        </div>       

        <div className="modal fade" id="modalD" tabindex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title"><i className="bi bi-pencil-square"></i> Editar Docente ID° {ModIdDocente}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {LoadingForm && 
                        <div className="progress mt-3">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }     
                       
                        <div className='row mt-2'>
                            <div className='col-md-6'>
                                <span className='label'>Apellidos y nombres: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={NombreDocente} onChange={(e) => setNombreDocente(e.target.value)} />
                            </div>     
                            <div className='col-md-3'>
                                <span className='label'>Identificación: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={Identificacion} onChange={(e) => setIdentificacion(e.target.value)} />
                            </div>     
                            <div className='col-md-3'>
                                <span className='label'>Sexo: </span>
                                <select className="form-select form-select-sm" aria-label="Default select example" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} value={SexoM} onChange={(e) => setSexoM(e.target.value)} >
                                        <option value='M'>Masculino</option>
                                        <option value='F'>Femenino</option>
                                </select>
                            </div>     
                        </div>
                        <div className='row mt-2'>
                            <div className='col-md-3'>
                                <span className='label'>Fecha Nac: </span>
                                <input type="date" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={FechaNacM}  onChange={handleFechaNacChange} />
                            </div>     
                            <div className='col-md-1'>
                                <span className='label'>Edad: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="edad" name="edad" value={EdadM} disabled  />
                            </div>
                            <div className='col-md-3'>
                                <span className='label'>Teléfono: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={TelefonoM} onChange={(e) => setTelefonoM(e.target.value)} />
                            </div>     
                            <div className='col-md-5'>
                                <span className='label'>Dirección: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={DireccionM} onChange={(e) => setDireccionM(e.target.value)} />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>E-Mail: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold', border: emailValido ? '1px solid #ced4da' : '1px solid #dc3545' }} className="form-control form-control-sm" id="nombres" name="nombres" value={EmailM} onChange={(e) => setEmailM(e.target.value)} />
                            </div>     
                            <div className='col-md-6'>
                                <span className='label'>Titulo: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={TituloM} onChange={(e) => setTituloM(e.target.value)} />
                            </div>     
                            <div className='col-md-3'>
                                <span className='label'>Escalafón: </span>
                                <input type="text" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} className="form-control form-control-sm" id="nombres" name="nombres" value={EscalafonM} onChange={(e) => setEscalafonM(e.target.value)} />
                            </div>     
                            <div className='col-md-3'>
                                <span className='label'>Estado: </span>
                                <select className="form-select form-select-sm" aria-label="Default select example" style={{ backgroundColor: '#eaf2ff', color: '#004085', fontWeight: 'bold' }} value={EstadoM} onChange={(e) => setEstadoM(e.target.value)} >
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                </select>
                            </div>     

                        </div>
                                            
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"><i className="bi bi-door-closed"></i> Cerrar</button>
                      <button type="button" className="btn btn-primary btn-sm" onClick={Guardar} disabled={NombreDocente === "" || Identificacion === "" || TelefonoM === "" || DireccionM === "" || emailValido === false || FechaNacM === ""  || TituloM === "" || EscalafonM === "" } data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Guardar Matricula"><i className="bi bi-clipboard-plus"></i> Guardar</button>                      
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
    </>
  )
}

export default Docentes