import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto de sesión
export const SessionContext = createContext();

// Crea el proveedor de sesión
export const SessionProvider = ({ children }) => {
  // Obtén los datos de usuario almacenados en el almacenamiento local al cargar la aplicación
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('userData')) || null);
  const [selectedCampus, setSelectedCampus] = useState(localStorage.getItem('selectedCampus') || 'default'); // Sede predeterminada por defecto

  // Función para iniciar sesión
  const login = (userData) => {
    setUser(userData);
  };

  // Función para cerrar sesión
  const logout = () => {   
    setUser(null);
    setSelectedCampus('default');
  };

  // Guarda los datos de usuario en el almacenamiento local al actualizar el estado de usuario
  useEffect(() => {
    localStorage.setItem('userData', JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    localStorage.setItem('selectedCampus', selectedCampus);
  }, [selectedCampus]);

  // Define los valores del contexto

  // Retorna el proveedor del contexto con los hijos proporcionados
  return (
    <SessionContext.Provider value={{ user, setUser, login, logout, selectedCampus, setSelectedCampus  }}>
      {children}
    </SessionContext.Provider>
  );
};
