import React, { useEffect, useState } from 'react'
import Grado from './Grado'
import { GetData } from '../../services/GetData'


const Grados = ({Periodo}) => {
  
    const [ListaGrados, setListaGrados] = useState([])
    const listaClasesColores = ['titulo_grado_naranja', 'titulo_grado_verde', 'titulo_grado_fuxia'];
    
    useEffect(() => {
        GetData('asignaciones/todosgrados/').then((result) => {
                    setListaGrados(result.grados)
        })
    }, [])
    
    const SelectColor = (num_grado) => {           
          if (num_grado < 6)
              return listaClasesColores[0];
            
          if (num_grado >= 6 && num_grado < 10)
              return listaClasesColores[1];
          if (num_grado >= 10)
              return listaClasesColores[2];
    }

  return (
            <>
            {ListaGrados.map((grado, index) => {
                return (
                    <div index={index} className="col-xxl-3 col-md-3 ">
                        <Grado Periodo={Periodo} index={index} grado={grado} color={SelectColor(grado.num_grado)} />
                    </div>
                 )
            })}            
            </>
  )
}

export default Grados