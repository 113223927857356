import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import 'moment/locale/es';

const PeriodoComponent = ({periodo, periodoNumber, onPeriodoChange }) => {
  const [periodoActual, setperiodoActual] = useState(parseInt(periodo.periodo_actual));
  const [Etapa, setEtapa] = useState('Calificaciones');
  const [fechaInicial, setFechaInicial] = useState(periodo.fecha_inicio);
  const [fechaFinal, setFechaFinal] = useState(periodo.fecha_cierre);
  const [fechaInicialNivelacion, setFechaInicialNivelacion] = useState(periodo.fecha_inicio_niv);
  const [fechaFinalNivelacion, setFechaFinalNivelacion] = useState(periodo.fecha_cierre_niv);
  const [error, setError] = useState('');

  useEffect(() => {
    let hoy = Moment().format('YYYY-MM-DD');
    if (periodo.fecha_cierre >= hoy)  {
        setEtapa('Calificaciones')
    }else{
        if (periodo.fecha_cierre_niv >= hoy)  {
            setEtapa('Nivelaciones')
        }else{
            setEtapa('Cerrado')
        }
    }
  }, [])
  

  const handleFechaInicialChange = (event) => {
    setFechaInicial(event.target.value);
  };

  const handleFechaFinalChange = (event) => {
    setFechaFinal(event.target.value);
  };

  const handleFechaInicialNivelacionChange = (event) => {
    setFechaInicialNivelacion(event.target.value);
  };

  const handleFechaFinalNivelacionChange = (event) => {
    setFechaFinalNivelacion(event.target.value);
  };

  const validateFechas = () => {
    if (fechaInicial === '' || fechaFinal === '' || fechaInicialNivelacion === '' || fechaFinalNivelacion === '') {
      setError('Todos los campos son obligatorios.');
      return false;
    }

    const fechaInicialDate = new Date(fechaInicial);
    const fechaFinalDate = new Date(fechaFinal);
    const fechaInicialNivelacionDate = new Date(fechaInicialNivelacion);
    const fechaFinalNivelacionDate = new Date(fechaFinalNivelacion);

    if (fechaInicialDate >= fechaFinalDate || fechaInicialNivelacionDate >= fechaFinalNivelacionDate) {
      setError('Las fechas finales deben ser posteriores a las fechas iniciales.');
      return false;
    }

    if (fechaFinalDate >= fechaInicialNivelacionDate) {
      setError('El rango de fechas de nivelación debe ser posterior al rango de fechas del período.');
      return false;
    }

    return true;
  };

  const handleGuardarClick = () => {
    if (validateFechas()) {
      // Realizar acciones adicionales si es necesario
      setError('');
      onPeriodoChange(
        periodoNumber,
        fechaInicial,
        fechaFinal,
        fechaInicialNivelacion,
        fechaFinalNivelacion,
      );
    }
  };

  const dias_faltantes = () => {
    let hoy = Moment().format('YYYY-MM-DD');
    
    if (periodo.fecha_cierre >= hoy)  {
     //   setEtapa('Calificaciones')
        const mensaje = Moment(periodo.fecha_cierre, "YYYYMMDD").endOf('day').fromNow();
        return mensaje;
    }else{   
        if (periodo.fecha_cierre_niv >= hoy)  {
           // setEtapa('Nivelaciones')
            const mensaje = Moment(periodo.fecha_cierre_niv, "YYYYMMDD").endOf('day').fromNow();
            return <div class="text-danger" style={{marginTop: '21px'}}>
                        <i class="bi bi-exclamation-octagon me-1"></i>
                        el tiempo de nivelación cierra en: <strong>{mensaje}</strong>
                    </div>;
        }else{
          const mensajePer = Moment(periodo.fecha_cierre, "YYYYMMDD").endOf('day').fromNow();
          const mensajeNiv = Moment(periodo.fecha_cierre_niv, "YYYYMMDD").endOf('day').fromNow();
          return <div class="text-danger" style={{marginTop: '21px'}}>
                        <i class="bi bi-exclamation-octagon me-1"></i>
                        Período cerro hace: <strong>{mensajePer}</strong>,  
                        Nivelación cerro hace: <strong>{mensajeNiv}</strong>
                    </div>;
            
          }
    }    
  }


  return (
    <div className="card">
      <div className="card-body">
        <div className='row'>
            <div className='col-md-2'>
                <h5 className="card-title">Período {periodoNumber}  
                {1 === periodoActual && <span className='ml-2 text-success'>({Etapa})</span>} </h5>
            </div>
            <div className='col-md-6'>
                {1 === periodoActual && 
                    <>{dias_faltantes()}</>
                }
            </div>
        </div>

        <div className="row">
        {error && <div className="col-md-12 text-danger">{error}</div>} 
          <div className='col-md-1 text-center'>
            <input className="form-check-input" style={{marginTop: '30px'}} type="radio" name="opActivo" id={`opcionp${periodoNumber}`} value={periodoNumber} 
                   checked={1 === periodoActual}
                   onChange={() => {}}
              />
          </div>  
          <div className="col-md-2">
            <span className="label">Fecha Inicial:</span>
            <input type="date" className="form-control form-control-sm estilocampo" value={fechaInicial} onChange={handleFechaInicialChange} />
          </div>
          <div className="col-md-2">
            <span className="label">Fecha Final:</span>
            <input type="date" className="form-control form-control-sm estilocampo" value={fechaFinal} onChange={handleFechaFinalChange} />
          </div>
          <div className="col-md-2">
            <span className="label">Fecha Inicial Nivelación:</span>
            <input
              type="date"
              className="form-control form-control-sm estilocampo"
              value={fechaInicialNivelacion}
              onChange={handleFechaInicialNivelacionChange}
            />
          </div>
          <div className="col-md-2">
            <span className="label">Fecha Final Nivelación:</span>
            <input
              type="date"
              className="form-control form-control-sm estilocampo"
              value={fechaFinalNivelacion}
              onChange={handleFechaFinalNivelacionChange}
            />
          </div>
          <div className="col-md-2">
            <button type="button" className="btn btn-primary" style={{marginTop: '21px'}} onClick={handleGuardarClick}>
              Guardar
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default PeriodoComponent;
