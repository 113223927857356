import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { PostData } from '../services/PostData';

const InputNotaIndicadorLetra = ({id_matricula, id_grado, id_grupo, id_asignatura, id_periodo, id_indicador, notav, handleKeyDown}) => {

    const [NotaAlumno, setNotaAlumno] = useState(notav)

    const handleKeyUp = (e) => {
        if (e.key === "Enter"){
  
          if (e.target.value === ""){
                handleKeyDown(e)
              return;
          }

           let notaing = e.target.value;
  
           if (notaing !== "A" && notaing !== "D" && notaing !== "E" && notaing !== "S"){
              toast.error('La nota debe ser menor A, D, E o S', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });     
              return;
           }
          
            guardar_nota(e);  
            
        }else{
             
        }
    }

    const onChangeNota = (e) => {
        const inputValue = e.target.value;
        
        // Expresión regular para validar el formato de la nota (1.0 - 5.0)
      //  const validNotaRegex = /^[1-4](\.[0-9])?|5(\.0)?$/;
    
        // Verificar si el valor ingresado cumple con el formato válido
     //   if (validNotaRegex.test(inputValue)) {
            // Actualizar el estado solo si el valor es válido
            setNotaAlumno(inputValue);
       // }
    }
    
    const MensajeError = (mensaje) => {
        toast.error(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            }); 
    }
    const MensajeSuccess = (mensaje) => {
        toast.success(mensaje, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            }); 
    }

    const guardar_nota = (e) => {    
        let datosenvio = {
            id_matricula : id_matricula,
            id_indicador : id_indicador,
            id_asignatura: id_asignatura,
            id_grado : id_grado,
            id_grupo : id_grupo,
            id_periodo: id_periodo,
            nota_alumno: NotaAlumno
        }      
       
       /*  if (!NotaAlumno){
            MensajeError('La nota debe estar entre 1.0 y 5.0')          
            return;
        } */

        

        PostData('calificaciones/guadarnota/', datosenvio).then ((result) =>{
            if (result.error === false){            
                MensajeSuccess('Nota guardada')  
                handleKeyDown(e)
            }else{
                MensajeError('Error al guardar la nota') 
            }
        })    
    }

  const deleteNota = () => {
        let datosenvio = {
            id_matricula : id_matricula,
            id_indicador : id_indicador,
            id_asignatura: id_asignatura,
            id_grado : id_grado,
            id_grupo : id_grupo,
            id_periodo: id_periodo
        }    
        PostData('calificaciones/deletenota/', datosenvio).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){              
                if (parseInt(responseJSON.proceso) === 1){
                    setNotaAlumno('')
                    MensajeSuccess('Nota borrada')  
                }
            }else{
                MensajeError('Error al eliminar la nota') 
            }
        }) 
    }

  return (
    <>
        <div className='d-flex'>
            <div className='mr-2'>
                <input type='text' 
                        style={{width:'45px'}} 
                        className='form-control form-control-sm estilocampo' 
                        name={id_matricula} 
                        id={id_matricula} 
                        value={NotaAlumno} 
                        
                        onKeyUp={handleKeyUp} 
                        onChange={onChangeNota}
                        />
            </div>
            <div>
                <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                        <Tooltip id={`tooltip-top`}>
                            Borrar Nota
                        </Tooltip>
                        }
                    >                      
                        <img   
                            src={`/assets/img/borrar.png`} 
                            className='icono_tabla' 
                            width={25} 
                            onClick={()=>deleteNota()} 
                            style={{visibility: NotaAlumno != "" ? 'visible' : 'hidden' }} /> 
                            
                </OverlayTrigger>
            </div>
        </div>
        <ToastContainer />
    </>
  )
}

export default InputNotaIndicadorLetra