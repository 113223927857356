import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'


const PerfilUsuario = () => {

    const [PerfilPersona, setPerfilPersona] = useState([])    
    const [FotoPerfil, setFotoPerfil] = useState("")    

    useEffect(() => {        
        GetData('usuarios/perfilusuario', null).then ((result) =>{
            let responseJSON = result;

            if (responseJSON.error === false){
                setPerfilPersona(result.datos)
                definirfotoperfil()
            }
        })
    }, [])
        


    const definirfotoperfil = () => {
        
        if (PerfilPersona.avatar)
            setFotoPerfil(process.env.REACT_APP_URL_FOTOS + PerfilPersona.fotoperfil);
        else{
           // if (PerfilPersona.sexo === 'M')
                setFotoPerfil('/assets/img/avatar_hombre.png')
          //  else
//                setFotoPerfil('/assets/img/avatar_mujer.png')
        }
    }


  return (
    <>
     <div className="pagetitle">
      <h1>Perfil</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="index.html">Home</a></li>
          <li className="breadcrumb-item">Usuario</li>
          <li className="breadcrumb-item active">Perfil</li>
        </ol>
      </nav>
    </div>

    <section className="section profile">

    <div className="row">
        <div className="col-xl-4">

          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

              <img src={FotoPerfil} alt="Profile" className="rounded-circle" />
              <h2>{PerfilPersona.nombres}</h2>
              <h3>{PerfilPersona.id_ministerio}</h3>
              <div className="social-links mt-2">
                <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

        </div>


        </div>
        
    </section>
    
    </>
  )
}

export default PerfilUsuario