import React from 'react';
import Rutas from './Rutas';
import { SessionProvider } from './SessionContext';

function App() {
  
  
  return (
    
      <SessionProvider>
          <Rutas />
      </SessionProvider>
    
  );
}

export default App;
