import React, { useEffect, useState } from 'react'
import { GetData } from '../services/GetData'
import { PostData } from '../services/PostData'
import Swal from 'sweetalert2'
import BotonTabla from '../components/BotonTabla'

function DesempeniosComport() {

    const [ListaTiposUS, setListaTiposUS] = useState([]) 
    const [ListaGados, setListaGados] = useState([]) 
    const [ListaDocentes, setListaDocentes] = useState([]) 
    const [ListaAsignatura, setListaAsignatura] = useState([]) 
    const [ListaIndicadores, setListaIndicadores] = useState([]) 
    const [Loading, setLoading] = useState(false)
    const [LoadingAs, setLoadingAs] = useState(true)
    const [LoadingTabla, setLoadingTabla] = useState(false)
    const [Grado, setGrado] = useState(null)
    const [NombreGrado, setNombreGrado] = useState("")
    const [Asignatura, setAsginatura] = useState(null)
    const [NombAsignatura, setNombAsginatura] = useState("")
    const [Periodo, setPeriodo] = useState(1)
    const [ModCodigo, setModCodigo] = useState("0")
    const [ModPeriodo, setModPeriodo] = useState("0")
    const [ModDescripcion, setModDescripcion] = useState("1")
    const [ModIdDesemp, setModIdDesemp] = useState(0)
 
    
    useEffect(() => {
        buscarindicadores()
    }, [])
    
    
    const buscarindicadores = () => {    
                setListaIndicadores([])
                setLoadingTabla(true)
                console.log("Ejecutando....")
                GetData('indicadorescompor/listacomport', null).then ((result) =>{
                    let responseJSON = result;
            
                    if (responseJSON.error === false){
                        setListaIndicadores(result.indicadores)
                        setLoadingTabla(false)
                    }
                })
    }

    const Modificar = (des) => {
          setModIdDesemp(des.id_ind_comport)
          setModCodigo(des.codigo_indic)
          setModDescripcion(des.descripcion_indic)  
    }

    const Nuevo = () => {
          setModIdDesemp(0)
          setModCodigo("")
          setModDescripcion("")  

          GetData('indicadorescompor/ultimocodigocomport', null).then ((result) =>{
                setModCodigo(result.codigo_indic)
          })
          

          const modal = document.getElementById('modalD');

          // Verificar si el modal existe antes de intentar abrirlo
          if (modal) {
            // Usar el método modal() para abrir el modal
            const modalInstancia = new window.bootstrap.Modal(modal);
            modalInstancia.show();
          }
    }

    const Guardar = () => {
        
        const datos = {
            id_ind_comport: ModIdDesemp ? ModIdDesemp : 0,
            codigo_indic: ModCodigo,
            descripcion_indic: ModDescripcion,           
        }
          
        PostData('indicadorescompor/save', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Desepeño Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  buscarindicadores();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const confirmarEliminacion = (id_indicador, codigo) => {
        Swal.fire({
          title: '¿Estás seguro?',
          text: 'Esta acción eliminará el indicador: ' + codigo,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            // Si el usuario confirma, realiza la eliminación
            BorrarDes(id_indicador);
          }
        });
      };

    const BorrarDes = (id_indicador) => {
        
        const datos = {
            id_indicador: id_indicador
        }
          
        PostData('indicadorescompor/deleteadmin', datos).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                Swal.fire({
                    title: 'Desepeño Guardado',
                    text: 'Guardado con exito.',
                    icon:"success",
                    confirmButtonText: 'Aceptar'
                  });
                  buscarindicadores();
             }else{
              Swal.fire({
                title: 'Error al Guardar',
                text: result.mensaje,
                icon: 'error',
                confirmButtonText: 'Aceptar'
              });
              
          }
        })
    }

    const Eliminar = (dato) => {

        Swal.fire({
          title: '¿Estás seguro?',
          text: '¡No podrás revertir esto! Código del indicador: ' + dato.codigo_indic,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminarlo'
      }).then((result) => {
          if (result.isConfirmed) {
              // Realiza la acción de eliminación si el usuario confirma
              const id_carga = dato.id_ind_comport;

              GetData('indicadorescompor/delete/' + id_carga, null).then ((result) =>{

                  let responseJSON = result;
      
                  if (responseJSON.error === false){
                      Swal.fire({
                          title: 'Eliminado',
                          text: 'El elemento ha sido eliminado correctamente',
                          icon: 'success'
                      });
                      buscarindicadores();
                  }
              })
              // Muestra un mensaje de éxito después de eliminar
            
          }
      });
  }


  return (
    <>
    <section className='section dashboard'>
            <div className="card">
            <div className='card-header'>Desempeños de Comportamiento</div>    
            <div className="card-body pb-0">

                <div className="formulario">
                    <form method="post" >
                        <div className='row'>
                           
                            <div className='col-md-6'>
                                <button type="button" className="btn btn-info mt-4" onClick={buscarindicadores} >
                                        <i className="bi bi-search"></i>
                                </button>
                                <button type="button" className="btn btn-primary mt-4 ml-2" onClick={Nuevo}  >
                                       Nuevo Desempeño
                                </button>
                            </div>  
                        </div>
                    </form>
                </div>
                <div className="card-body pb-0">                   
                    <div className='row'>
                       
                    </div>
                    {LoadingTabla && 
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>      
                    }      
                    <table className="table table-striped mt-3">
                        <thead>
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Descripción</th>
                            <th scope="col" style={{width:'7%'}}>N° Notas</th>
                            <th scope="col" style={{width:'10%'}}>OP</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListaIndicadores.map((us, index) => 
                            <tr>
                                <th scope="row">{us.id_ind_comport}</th>
                                <td>{us.descripcion_indic}</td>
                                <td align='center'>{us.notas}</td>
                                <td>    
                                    <BotonTabla icono='lapiz.png' titulo='Editar Desempeño' evento={()=>Modificar(us)} />
                                    {parseInt(us.notas) === 0 && 
                                        <BotonTabla icono='borrar.png' titulo='Borrar Desempeño' evento={()=>confirmarEliminacion(us.id_indicador, us.codigo_indic)} />
                                    }
                                </td>
                            </tr>
                        )}    
                        
                        </tbody>
                    </table>
               </div>
            </div>
            </div>
        </section>        


        <div className="modal fade" id="modalD" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Editar Desempeño</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className='col-md-6'>
                                <strong>Código: </strong>
                                <input type="text" className="form-control" id="nombres" name="nombres" value={ModCodigo} onChange={(e) => setModCodigo(e.target.value)} />
                            </div>     
                        </div>
                       {/*  <div className='row'>
                            <div className='col-md-6'>
                               <strong>Periodos: </strong>
                                <select className="form-select sm" aria-label="Default select example" value={ModPeriodo} onChange={(e) => setModPeriodo(e.target.value)} >
                                    <option defaultValue={1}>Período 1</option>
                                    <option value={2}>Período 2</option>
                                    <option value={3}>Período 3</option>
                                    <option value={4}>Período 4</option>                               
                                </select> 
                            </div>     
                        </div> */}
                        <div className='row'>
                                <div className='col-md-12'>
                                <strong>Descripción</strong>
                                <textarea className="form-control" placeholder="Digite la descripción" id="descripcion" style={{height: '200px'}}
                                value={ModDescripcion} onChange={(e) => setModDescripcion(e.target.value)}
                                ></textarea>
                                </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary" onClick={Guardar} disabled={ModDescripcion === "" || ModCodigo === ""} >Guardar</button>
                    </div>
                  </div>
                </div>
              </div>
    </>
  )
}

export default DesempeniosComport