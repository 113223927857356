import React, { useEffect, useState } from 'react'
import { GetData } from '../../services/GetData'

const RankinAlumnosPeriodo = () => {
    const [ListadoAlumnosP, setListadoAlumnosP] = useState([])  
    const [ListadoAlumnosB, setListadoAlumnosM] = useState([])  
    const [ListadoGradoP, setListadoGradoP] = useState([])  
    const [ListadoGradoB, setListadoGradoB] = useState([])  
    const [Periodo, setPeriodo] = useState(0)  
    const [PeriodoActual, setPeriodoActual] = useState(0)  
    const [Loading, setLoading] = useState(false)  

   

  useEffect(() => {
    const fetchData = async () => {
        try {

            const periodoResult = await GetData('periodos/actual/', null);
            

            setPeriodo(periodoResult.periodo);
            setPeriodoActual(periodoResult.periodo);
           

        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setLoading(false);
        }
    };

    fetchData();
}, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            const listado = await GetData('estadisticas/mejoresalumnosperiodo/' + Periodo, null);
            setListadoAlumnosP(listado.primaria);
            setListadoAlumnosM(listado.secundaria);
            setListadoGradoP(listado.mejorgradop);
            setListadoGradoB(listado.mejorgradob);
            setLoading(false)
        }
        fetchData();

    }, [Periodo])
    

  return (
    <>
        <section className='section dashboard'>
            <div className="card">
                <div className='card-header'>Mejores Promedios del Período</div>                    

                <div className="card-body pb-0 mt-3">
                    <div className='row'>
                            <div className='col-md-2'>
                                    <strong>Período: </strong>
                                    <select className="form-select form-select-sm estilocampo" aria-label="Default select example" value={Periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                    {Array.from({length: PeriodoActual}, (_, index) => index + 1).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>      
                    </div>    
                    {Loading && 
                        <div className="progress mt-1 mb-2">
                            <div className="progress-bar progress-bar-striped bg-info progress-bar-animated" role="progressbar" style={{width : '100%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>      
                        }      
                    <div className='card-title mt-1'>Primaria</div>
                    
                    <h6>Mejores puntajes del Período</h6>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnosP.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                            </tr>
                        )}    
                        </tbody>
                    </table>
                    <h6>Mejores puntajes por grado</h6>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoGradoP.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                            </tr>
                        )}    
                        </tbody>
                    </table>

                    <div className='card-title '>Bachillerato</div>
                    <h6>Mejores puntajes</h6>
                    <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoAlumnosB.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                            </tr>
                        )}    
                        </tbody>
                        </table>
                        <h6>Mejores puntajes por grado</h6>
                         <table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">Puesto</th>
                            <th scope="col">Grado</th>
                            <th scope="col">Alumno</th>
                            <th scope="col">Promedio</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ListadoGradoB.map((grado, index) => 
                            <tr key={index}>
                                <td>{index+1}</td>
                                <td>{grado.nombre_grado} - 0{grado.id_grupo}</td>                                                           
                                <td>{grado.nombres_apellidos_al}</td>
                                <td>{grado.promedio}</td>
                            </tr>
                        )}    
                        </tbody>
                        </table>
                </div>
            </div>
        </section>
    </>
    )
}

export default RankinAlumnosPeriodo